import { ApolloClient } from "apollo-client"
import { createHttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"
import { setContext } from "apollo-link-context"
import { onError } from "apollo-link-error"
import { from } from "apollo-link"
import fetch from "isomorphic-unfetch"
import Cookies from "universal-cookie"
const cookies = new Cookies()

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const httpLink = createHttpLink({
  fetch,
  uri: process.env.GATSBY_HASURA_GRAPHQL_URI,
  credentials: "same-origin",
})

const authLink = setContext((_, { headers }) => {
  const token = cookies.get("token")

  return {
    headers: {
      ...headers,
      Authorization: process.browser ? `Bearer ${token}` : "",
    },
  }
})

export const client = new ApolloClient({
  ssrMode: false,
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
})
