import React, { useEffect, useState } from "react"
import { ApolloProvider } from "@apollo/react-hooks"
import { CookiesProvider } from "react-cookie"
import { client } from "./apollo/client"
import { AppContext } from "./context"
import { useCookies } from "react-cookie"
import * as jwt from "jsonwebtoken"
import { useInsertUserMutation } from "./apollo/graphql"
import * as Sentry from "@sentry/browser"

Sentry.init({
  dsn: "http://337f8ddbb69c472bb340d6e84a82e1de@167.172.198.180:9000/2",
  environment: process.env.NODE_ENV,
})

const App = ({ children }) => {
  const [{ token }, setCookie, removeCookie] = useCookies(["token"])
  const [insertUser] = useInsertUserMutation()

  const [contextState, setContextState] = useState({
    user: undefined,
  })

  useEffect(() => {
    const decodedToken = jwt.decode(token)
    if (decodedToken) {
      setContextState({
        user: {
          id: decodedToken.id,
          role: decodedToken.role,
          email: decodedToken.email,
        },
      })
      insertUser({
        variables: {
          email: decodedToken.email,
          first_name: decodedToken.given_name,
          last_name: decodedToken.family_name,
        },
      }).catch(console.error)
      Sentry.setUser({
        email: decodedToken.email,
      })
    } else {
      setContextState({
        user: undefined,
      })
    }
  }, [token])

  return (
    <AppContext.Provider value={contextState}>{children}</AppContext.Provider>
  )
}

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  return (
    <CookiesProvider>
      <ApolloProvider client={client}>
        <App>{element}</App>
      </ApolloProvider>
    </CookiesProvider>
  )
}
