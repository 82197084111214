import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  bigint: any,
  timestamptz: any,
  uuid: any,
};


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>,
  _gt?: Maybe<Scalars['bigint']>,
  _gte?: Maybe<Scalars['bigint']>,
  _in?: Maybe<Array<Scalars['bigint']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['bigint']>,
  _lte?: Maybe<Scalars['bigint']>,
  _neq?: Maybe<Scalars['bigint']>,
  _nin?: Maybe<Array<Scalars['bigint']>>,
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>,
  _gt?: Maybe<Scalars['Boolean']>,
  _gte?: Maybe<Scalars['Boolean']>,
  _in?: Maybe<Array<Scalars['Boolean']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['Boolean']>,
  _lte?: Maybe<Scalars['Boolean']>,
  _neq?: Maybe<Scalars['Boolean']>,
  _nin?: Maybe<Array<Scalars['Boolean']>>,
};

/** mutation root */
export type Mutation_Root = {
   __typename?: 'mutation_root',
  /** delete data from the table: "poll_submission_options" */
  delete_poll_submission_options?: Maybe<Poll_Submission_Options_Mutation_Response>,
  /** delete data from the table: "polls" */
  delete_polls?: Maybe<Polls_Mutation_Response>,
  /** delete data from the table: "submissions" */
  delete_submissions?: Maybe<Submissions_Mutation_Response>,
  /** delete data from the table: "submitters" */
  delete_submitters?: Maybe<Submitters_Mutation_Response>,
  /** delete data from the table: "unassigned_submissions" */
  delete_unassigned_submissions?: Maybe<Unassigned_Submissions_Mutation_Response>,
  /** delete data from the table: "user_submitter_recommendations" */
  delete_user_submitter_recommendations?: Maybe<User_Submitter_Recommendations_Mutation_Response>,
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>,
  /** delete data from the table: "votes" */
  delete_votes?: Maybe<Votes_Mutation_Response>,
  /** insert data into the table: "poll_submission_options" */
  insert_poll_submission_options?: Maybe<Poll_Submission_Options_Mutation_Response>,
  /** insert data into the table: "polls" */
  insert_polls?: Maybe<Polls_Mutation_Response>,
  /** insert data into the table: "submissions" */
  insert_submissions?: Maybe<Submissions_Mutation_Response>,
  /** insert data into the table: "submitters" */
  insert_submitters?: Maybe<Submitters_Mutation_Response>,
  /** insert data into the table: "unassigned_submissions" */
  insert_unassigned_submissions?: Maybe<Unassigned_Submissions_Mutation_Response>,
  /** insert data into the table: "user_submitter_recommendations" */
  insert_user_submitter_recommendations?: Maybe<User_Submitter_Recommendations_Mutation_Response>,
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>,
  /** insert data into the table: "votes" */
  insert_votes?: Maybe<Votes_Mutation_Response>,
  /** update data of the table: "poll_submission_options" */
  update_poll_submission_options?: Maybe<Poll_Submission_Options_Mutation_Response>,
  /** update data of the table: "polls" */
  update_polls?: Maybe<Polls_Mutation_Response>,
  /** update data of the table: "submissions" */
  update_submissions?: Maybe<Submissions_Mutation_Response>,
  /** update data of the table: "submitters" */
  update_submitters?: Maybe<Submitters_Mutation_Response>,
  /** update data of the table: "unassigned_submissions" */
  update_unassigned_submissions?: Maybe<Unassigned_Submissions_Mutation_Response>,
  /** update data of the table: "user_submitter_recommendations" */
  update_user_submitter_recommendations?: Maybe<User_Submitter_Recommendations_Mutation_Response>,
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>,
  /** update data of the table: "votes" */
  update_votes?: Maybe<Votes_Mutation_Response>,
};


/** mutation root */
export type Mutation_RootDelete_Poll_Submission_OptionsArgs = {
  where: Poll_Submission_Options_Bool_Exp
};


/** mutation root */
export type Mutation_RootDelete_PollsArgs = {
  where: Polls_Bool_Exp
};


/** mutation root */
export type Mutation_RootDelete_SubmissionsArgs = {
  where: Submissions_Bool_Exp
};


/** mutation root */
export type Mutation_RootDelete_SubmittersArgs = {
  where: Submitters_Bool_Exp
};


/** mutation root */
export type Mutation_RootDelete_Unassigned_SubmissionsArgs = {
  where: Unassigned_Submissions_Bool_Exp
};


/** mutation root */
export type Mutation_RootDelete_User_Submitter_RecommendationsArgs = {
  where: User_Submitter_Recommendations_Bool_Exp
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp
};


/** mutation root */
export type Mutation_RootDelete_VotesArgs = {
  where: Votes_Bool_Exp
};


/** mutation root */
export type Mutation_RootInsert_Poll_Submission_OptionsArgs = {
  objects: Array<Poll_Submission_Options_Insert_Input>,
  on_conflict?: Maybe<Poll_Submission_Options_On_Conflict>
};


/** mutation root */
export type Mutation_RootInsert_PollsArgs = {
  objects: Array<Polls_Insert_Input>,
  on_conflict?: Maybe<Polls_On_Conflict>
};


/** mutation root */
export type Mutation_RootInsert_SubmissionsArgs = {
  objects: Array<Submissions_Insert_Input>,
  on_conflict?: Maybe<Submissions_On_Conflict>
};


/** mutation root */
export type Mutation_RootInsert_SubmittersArgs = {
  objects: Array<Submitters_Insert_Input>,
  on_conflict?: Maybe<Submitters_On_Conflict>
};


/** mutation root */
export type Mutation_RootInsert_Unassigned_SubmissionsArgs = {
  objects: Array<Unassigned_Submissions_Insert_Input>
};


/** mutation root */
export type Mutation_RootInsert_User_Submitter_RecommendationsArgs = {
  objects: Array<User_Submitter_Recommendations_Insert_Input>,
  on_conflict?: Maybe<User_Submitter_Recommendations_On_Conflict>
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>,
  on_conflict?: Maybe<Users_On_Conflict>
};


/** mutation root */
export type Mutation_RootInsert_VotesArgs = {
  objects: Array<Votes_Insert_Input>,
  on_conflict?: Maybe<Votes_On_Conflict>
};


/** mutation root */
export type Mutation_RootUpdate_Poll_Submission_OptionsArgs = {
  _set?: Maybe<Poll_Submission_Options_Set_Input>,
  where: Poll_Submission_Options_Bool_Exp
};


/** mutation root */
export type Mutation_RootUpdate_PollsArgs = {
  _set?: Maybe<Polls_Set_Input>,
  where: Polls_Bool_Exp
};


/** mutation root */
export type Mutation_RootUpdate_SubmissionsArgs = {
  _set?: Maybe<Submissions_Set_Input>,
  where: Submissions_Bool_Exp
};


/** mutation root */
export type Mutation_RootUpdate_SubmittersArgs = {
  _set?: Maybe<Submitters_Set_Input>,
  where: Submitters_Bool_Exp
};


/** mutation root */
export type Mutation_RootUpdate_Unassigned_SubmissionsArgs = {
  _set?: Maybe<Unassigned_Submissions_Set_Input>,
  where: Unassigned_Submissions_Bool_Exp
};


/** mutation root */
export type Mutation_RootUpdate_User_Submitter_RecommendationsArgs = {
  _set?: Maybe<User_Submitter_Recommendations_Set_Input>,
  where: User_Submitter_Recommendations_Bool_Exp
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>,
  where: Users_Bool_Exp
};


/** mutation root */
export type Mutation_RootUpdate_VotesArgs = {
  _set?: Maybe<Votes_Set_Input>,
  where: Votes_Bool_Exp
};

/** columns and relationships of "online_users" */
export type Online_Users = {
   __typename?: 'online_users',
  count?: Maybe<Scalars['bigint']>,
};

/** aggregated selection of "online_users" */
export type Online_Users_Aggregate = {
   __typename?: 'online_users_aggregate',
  aggregate?: Maybe<Online_Users_Aggregate_Fields>,
  nodes: Array<Online_Users>,
};

/** aggregate fields of "online_users" */
export type Online_Users_Aggregate_Fields = {
   __typename?: 'online_users_aggregate_fields',
  avg?: Maybe<Online_Users_Avg_Fields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<Online_Users_Max_Fields>,
  min?: Maybe<Online_Users_Min_Fields>,
  stddev?: Maybe<Online_Users_Stddev_Fields>,
  stddev_pop?: Maybe<Online_Users_Stddev_Pop_Fields>,
  stddev_samp?: Maybe<Online_Users_Stddev_Samp_Fields>,
  sum?: Maybe<Online_Users_Sum_Fields>,
  var_pop?: Maybe<Online_Users_Var_Pop_Fields>,
  var_samp?: Maybe<Online_Users_Var_Samp_Fields>,
  variance?: Maybe<Online_Users_Variance_Fields>,
};


/** aggregate fields of "online_users" */
export type Online_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Online_Users_Select_Column>>,
  distinct?: Maybe<Scalars['Boolean']>
};

/** order by aggregate values of table "online_users" */
export type Online_Users_Aggregate_Order_By = {
  avg?: Maybe<Online_Users_Avg_Order_By>,
  count?: Maybe<Order_By>,
  max?: Maybe<Online_Users_Max_Order_By>,
  min?: Maybe<Online_Users_Min_Order_By>,
  stddev?: Maybe<Online_Users_Stddev_Order_By>,
  stddev_pop?: Maybe<Online_Users_Stddev_Pop_Order_By>,
  stddev_samp?: Maybe<Online_Users_Stddev_Samp_Order_By>,
  sum?: Maybe<Online_Users_Sum_Order_By>,
  var_pop?: Maybe<Online_Users_Var_Pop_Order_By>,
  var_samp?: Maybe<Online_Users_Var_Samp_Order_By>,
  variance?: Maybe<Online_Users_Variance_Order_By>,
};

/** aggregate avg on columns */
export type Online_Users_Avg_Fields = {
   __typename?: 'online_users_avg_fields',
  count?: Maybe<Scalars['Float']>,
};

/** order by avg() on columns of table "online_users" */
export type Online_Users_Avg_Order_By = {
  count?: Maybe<Order_By>,
};

/** Boolean expression to filter rows from the table "online_users". All fields are combined with a logical 'AND'. */
export type Online_Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Online_Users_Bool_Exp>>>,
  _not?: Maybe<Online_Users_Bool_Exp>,
  _or?: Maybe<Array<Maybe<Online_Users_Bool_Exp>>>,
  count?: Maybe<Bigint_Comparison_Exp>,
};

/** aggregate max on columns */
export type Online_Users_Max_Fields = {
   __typename?: 'online_users_max_fields',
  count?: Maybe<Scalars['bigint']>,
};

/** order by max() on columns of table "online_users" */
export type Online_Users_Max_Order_By = {
  count?: Maybe<Order_By>,
};

/** aggregate min on columns */
export type Online_Users_Min_Fields = {
   __typename?: 'online_users_min_fields',
  count?: Maybe<Scalars['bigint']>,
};

/** order by min() on columns of table "online_users" */
export type Online_Users_Min_Order_By = {
  count?: Maybe<Order_By>,
};

/** ordering options when selecting data from "online_users" */
export type Online_Users_Order_By = {
  count?: Maybe<Order_By>,
};

/** select columns of table "online_users" */
export enum Online_Users_Select_Column {
  /** column name */
  Count = 'count'
}

/** aggregate stddev on columns */
export type Online_Users_Stddev_Fields = {
   __typename?: 'online_users_stddev_fields',
  count?: Maybe<Scalars['Float']>,
};

/** order by stddev() on columns of table "online_users" */
export type Online_Users_Stddev_Order_By = {
  count?: Maybe<Order_By>,
};

/** aggregate stddev_pop on columns */
export type Online_Users_Stddev_Pop_Fields = {
   __typename?: 'online_users_stddev_pop_fields',
  count?: Maybe<Scalars['Float']>,
};

/** order by stddev_pop() on columns of table "online_users" */
export type Online_Users_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>,
};

/** aggregate stddev_samp on columns */
export type Online_Users_Stddev_Samp_Fields = {
   __typename?: 'online_users_stddev_samp_fields',
  count?: Maybe<Scalars['Float']>,
};

/** order by stddev_samp() on columns of table "online_users" */
export type Online_Users_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>,
};

/** aggregate sum on columns */
export type Online_Users_Sum_Fields = {
   __typename?: 'online_users_sum_fields',
  count?: Maybe<Scalars['bigint']>,
};

/** order by sum() on columns of table "online_users" */
export type Online_Users_Sum_Order_By = {
  count?: Maybe<Order_By>,
};

/** aggregate var_pop on columns */
export type Online_Users_Var_Pop_Fields = {
   __typename?: 'online_users_var_pop_fields',
  count?: Maybe<Scalars['Float']>,
};

/** order by var_pop() on columns of table "online_users" */
export type Online_Users_Var_Pop_Order_By = {
  count?: Maybe<Order_By>,
};

/** aggregate var_samp on columns */
export type Online_Users_Var_Samp_Fields = {
   __typename?: 'online_users_var_samp_fields',
  count?: Maybe<Scalars['Float']>,
};

/** order by var_samp() on columns of table "online_users" */
export type Online_Users_Var_Samp_Order_By = {
  count?: Maybe<Order_By>,
};

/** aggregate variance on columns */
export type Online_Users_Variance_Fields = {
   __typename?: 'online_users_variance_fields',
  count?: Maybe<Scalars['Float']>,
};

/** order by variance() on columns of table "online_users" */
export type Online_Users_Variance_Order_By = {
  count?: Maybe<Order_By>,
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "poll_submission_options" */
export type Poll_Submission_Options = {
   __typename?: 'poll_submission_options',
  created_at: Scalars['timestamptz'],
  is_active: Scalars['Boolean'],
  /** An object relationship */
  poll: Polls,
  poll_id: Scalars['String'],
  /** An object relationship */
  submission: Submissions,
  submission_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
};

/** aggregated selection of "poll_submission_options" */
export type Poll_Submission_Options_Aggregate = {
   __typename?: 'poll_submission_options_aggregate',
  aggregate?: Maybe<Poll_Submission_Options_Aggregate_Fields>,
  nodes: Array<Poll_Submission_Options>,
};

/** aggregate fields of "poll_submission_options" */
export type Poll_Submission_Options_Aggregate_Fields = {
   __typename?: 'poll_submission_options_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<Poll_Submission_Options_Max_Fields>,
  min?: Maybe<Poll_Submission_Options_Min_Fields>,
};


/** aggregate fields of "poll_submission_options" */
export type Poll_Submission_Options_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Poll_Submission_Options_Select_Column>>,
  distinct?: Maybe<Scalars['Boolean']>
};

/** order by aggregate values of table "poll_submission_options" */
export type Poll_Submission_Options_Aggregate_Order_By = {
  count?: Maybe<Order_By>,
  max?: Maybe<Poll_Submission_Options_Max_Order_By>,
  min?: Maybe<Poll_Submission_Options_Min_Order_By>,
};

/** input type for inserting array relation for remote table "poll_submission_options" */
export type Poll_Submission_Options_Arr_Rel_Insert_Input = {
  data: Array<Poll_Submission_Options_Insert_Input>,
  on_conflict?: Maybe<Poll_Submission_Options_On_Conflict>,
};

/** Boolean expression to filter rows from the table "poll_submission_options". All fields are combined with a logical 'AND'. */
export type Poll_Submission_Options_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Poll_Submission_Options_Bool_Exp>>>,
  _not?: Maybe<Poll_Submission_Options_Bool_Exp>,
  _or?: Maybe<Array<Maybe<Poll_Submission_Options_Bool_Exp>>>,
  created_at?: Maybe<Timestamptz_Comparison_Exp>,
  is_active?: Maybe<Boolean_Comparison_Exp>,
  poll?: Maybe<Polls_Bool_Exp>,
  poll_id?: Maybe<String_Comparison_Exp>,
  submission?: Maybe<Submissions_Bool_Exp>,
  submission_id?: Maybe<String_Comparison_Exp>,
  updated_at?: Maybe<Timestamptz_Comparison_Exp>,
};

/** unique or primary key constraints on table "poll_submission_options" */
export enum Poll_Submission_Options_Constraint {
  /** unique or primary key constraint */
  PollSubmissionOptionPkey = 'poll_submission_option_pkey'
}

/** input type for inserting data into table "poll_submission_options" */
export type Poll_Submission_Options_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  is_active?: Maybe<Scalars['Boolean']>,
  poll?: Maybe<Polls_Obj_Rel_Insert_Input>,
  poll_id?: Maybe<Scalars['String']>,
  submission?: Maybe<Submissions_Obj_Rel_Insert_Input>,
  submission_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
};

/** aggregate max on columns */
export type Poll_Submission_Options_Max_Fields = {
   __typename?: 'poll_submission_options_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  poll_id?: Maybe<Scalars['String']>,
  submission_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
};

/** order by max() on columns of table "poll_submission_options" */
export type Poll_Submission_Options_Max_Order_By = {
  created_at?: Maybe<Order_By>,
  poll_id?: Maybe<Order_By>,
  submission_id?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
};

/** aggregate min on columns */
export type Poll_Submission_Options_Min_Fields = {
   __typename?: 'poll_submission_options_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  poll_id?: Maybe<Scalars['String']>,
  submission_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
};

/** order by min() on columns of table "poll_submission_options" */
export type Poll_Submission_Options_Min_Order_By = {
  created_at?: Maybe<Order_By>,
  poll_id?: Maybe<Order_By>,
  submission_id?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
};

/** response of any mutation on the table "poll_submission_options" */
export type Poll_Submission_Options_Mutation_Response = {
   __typename?: 'poll_submission_options_mutation_response',
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'],
  /** data of the affected rows by the mutation */
  returning: Array<Poll_Submission_Options>,
};

/** input type for inserting object relation for remote table "poll_submission_options" */
export type Poll_Submission_Options_Obj_Rel_Insert_Input = {
  data: Poll_Submission_Options_Insert_Input,
  on_conflict?: Maybe<Poll_Submission_Options_On_Conflict>,
};

/** on conflict condition type for table "poll_submission_options" */
export type Poll_Submission_Options_On_Conflict = {
  constraint: Poll_Submission_Options_Constraint,
  update_columns: Array<Poll_Submission_Options_Update_Column>,
  where?: Maybe<Poll_Submission_Options_Bool_Exp>,
};

/** ordering options when selecting data from "poll_submission_options" */
export type Poll_Submission_Options_Order_By = {
  created_at?: Maybe<Order_By>,
  is_active?: Maybe<Order_By>,
  poll?: Maybe<Polls_Order_By>,
  poll_id?: Maybe<Order_By>,
  submission?: Maybe<Submissions_Order_By>,
  submission_id?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
};

/** select columns of table "poll_submission_options" */
export enum Poll_Submission_Options_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PollId = 'poll_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "poll_submission_options" */
export type Poll_Submission_Options_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  is_active?: Maybe<Scalars['Boolean']>,
  poll_id?: Maybe<Scalars['String']>,
  submission_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
};

/** update columns of table "poll_submission_options" */
export enum Poll_Submission_Options_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PollId = 'poll_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings = {
   __typename?: 'poll_submission_vote_rankings',
  last_vote_at?: Maybe<Scalars['timestamptz']>,
  /** An object relationship */
  poll?: Maybe<Polls>,
  poll_id?: Maybe<Scalars['String']>,
  /** An object relationship */
  submission?: Maybe<Submissions>,
  submission_id?: Maybe<Scalars['String']>,
  vote_count?: Maybe<Scalars['bigint']>,
};

/** aggregated selection of "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Aggregate = {
   __typename?: 'poll_submission_vote_rankings_aggregate',
  aggregate?: Maybe<Poll_Submission_Vote_Rankings_Aggregate_Fields>,
  nodes: Array<Poll_Submission_Vote_Rankings>,
};

/** aggregate fields of "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Aggregate_Fields = {
   __typename?: 'poll_submission_vote_rankings_aggregate_fields',
  avg?: Maybe<Poll_Submission_Vote_Rankings_Avg_Fields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<Poll_Submission_Vote_Rankings_Max_Fields>,
  min?: Maybe<Poll_Submission_Vote_Rankings_Min_Fields>,
  stddev?: Maybe<Poll_Submission_Vote_Rankings_Stddev_Fields>,
  stddev_pop?: Maybe<Poll_Submission_Vote_Rankings_Stddev_Pop_Fields>,
  stddev_samp?: Maybe<Poll_Submission_Vote_Rankings_Stddev_Samp_Fields>,
  sum?: Maybe<Poll_Submission_Vote_Rankings_Sum_Fields>,
  var_pop?: Maybe<Poll_Submission_Vote_Rankings_Var_Pop_Fields>,
  var_samp?: Maybe<Poll_Submission_Vote_Rankings_Var_Samp_Fields>,
  variance?: Maybe<Poll_Submission_Vote_Rankings_Variance_Fields>,
};


/** aggregate fields of "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Poll_Submission_Vote_Rankings_Select_Column>>,
  distinct?: Maybe<Scalars['Boolean']>
};

/** order by aggregate values of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Aggregate_Order_By = {
  avg?: Maybe<Poll_Submission_Vote_Rankings_Avg_Order_By>,
  count?: Maybe<Order_By>,
  max?: Maybe<Poll_Submission_Vote_Rankings_Max_Order_By>,
  min?: Maybe<Poll_Submission_Vote_Rankings_Min_Order_By>,
  stddev?: Maybe<Poll_Submission_Vote_Rankings_Stddev_Order_By>,
  stddev_pop?: Maybe<Poll_Submission_Vote_Rankings_Stddev_Pop_Order_By>,
  stddev_samp?: Maybe<Poll_Submission_Vote_Rankings_Stddev_Samp_Order_By>,
  sum?: Maybe<Poll_Submission_Vote_Rankings_Sum_Order_By>,
  var_pop?: Maybe<Poll_Submission_Vote_Rankings_Var_Pop_Order_By>,
  var_samp?: Maybe<Poll_Submission_Vote_Rankings_Var_Samp_Order_By>,
  variance?: Maybe<Poll_Submission_Vote_Rankings_Variance_Order_By>,
};

/** aggregate avg on columns */
export type Poll_Submission_Vote_Rankings_Avg_Fields = {
   __typename?: 'poll_submission_vote_rankings_avg_fields',
  vote_count?: Maybe<Scalars['Float']>,
};

/** order by avg() on columns of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Avg_Order_By = {
  vote_count?: Maybe<Order_By>,
};

/** 
 * Boolean expression to filter rows from the table
 * "poll_submission_vote_rankings". All fields are combined with a logical 'AND'.
 **/
export type Poll_Submission_Vote_Rankings_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Poll_Submission_Vote_Rankings_Bool_Exp>>>,
  _not?: Maybe<Poll_Submission_Vote_Rankings_Bool_Exp>,
  _or?: Maybe<Array<Maybe<Poll_Submission_Vote_Rankings_Bool_Exp>>>,
  last_vote_at?: Maybe<Timestamptz_Comparison_Exp>,
  poll?: Maybe<Polls_Bool_Exp>,
  poll_id?: Maybe<String_Comparison_Exp>,
  submission?: Maybe<Submissions_Bool_Exp>,
  submission_id?: Maybe<String_Comparison_Exp>,
  vote_count?: Maybe<Bigint_Comparison_Exp>,
};

/** aggregate max on columns */
export type Poll_Submission_Vote_Rankings_Max_Fields = {
   __typename?: 'poll_submission_vote_rankings_max_fields',
  last_vote_at?: Maybe<Scalars['timestamptz']>,
  poll_id?: Maybe<Scalars['String']>,
  submission_id?: Maybe<Scalars['String']>,
  vote_count?: Maybe<Scalars['bigint']>,
};

/** order by max() on columns of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Max_Order_By = {
  last_vote_at?: Maybe<Order_By>,
  poll_id?: Maybe<Order_By>,
  submission_id?: Maybe<Order_By>,
  vote_count?: Maybe<Order_By>,
};

/** aggregate min on columns */
export type Poll_Submission_Vote_Rankings_Min_Fields = {
   __typename?: 'poll_submission_vote_rankings_min_fields',
  last_vote_at?: Maybe<Scalars['timestamptz']>,
  poll_id?: Maybe<Scalars['String']>,
  submission_id?: Maybe<Scalars['String']>,
  vote_count?: Maybe<Scalars['bigint']>,
};

/** order by min() on columns of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Min_Order_By = {
  last_vote_at?: Maybe<Order_By>,
  poll_id?: Maybe<Order_By>,
  submission_id?: Maybe<Order_By>,
  vote_count?: Maybe<Order_By>,
};

/** ordering options when selecting data from "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Order_By = {
  last_vote_at?: Maybe<Order_By>,
  poll?: Maybe<Polls_Order_By>,
  poll_id?: Maybe<Order_By>,
  submission?: Maybe<Submissions_Order_By>,
  submission_id?: Maybe<Order_By>,
  vote_count?: Maybe<Order_By>,
};

/** select columns of table "poll_submission_vote_rankings" */
export enum Poll_Submission_Vote_Rankings_Select_Column {
  /** column name */
  LastVoteAt = 'last_vote_at',
  /** column name */
  PollId = 'poll_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  VoteCount = 'vote_count'
}

/** aggregate stddev on columns */
export type Poll_Submission_Vote_Rankings_Stddev_Fields = {
   __typename?: 'poll_submission_vote_rankings_stddev_fields',
  vote_count?: Maybe<Scalars['Float']>,
};

/** order by stddev() on columns of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Stddev_Order_By = {
  vote_count?: Maybe<Order_By>,
};

/** aggregate stddev_pop on columns */
export type Poll_Submission_Vote_Rankings_Stddev_Pop_Fields = {
   __typename?: 'poll_submission_vote_rankings_stddev_pop_fields',
  vote_count?: Maybe<Scalars['Float']>,
};

/** order by stddev_pop() on columns of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Stddev_Pop_Order_By = {
  vote_count?: Maybe<Order_By>,
};

/** aggregate stddev_samp on columns */
export type Poll_Submission_Vote_Rankings_Stddev_Samp_Fields = {
   __typename?: 'poll_submission_vote_rankings_stddev_samp_fields',
  vote_count?: Maybe<Scalars['Float']>,
};

/** order by stddev_samp() on columns of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Stddev_Samp_Order_By = {
  vote_count?: Maybe<Order_By>,
};

/** aggregate sum on columns */
export type Poll_Submission_Vote_Rankings_Sum_Fields = {
   __typename?: 'poll_submission_vote_rankings_sum_fields',
  vote_count?: Maybe<Scalars['bigint']>,
};

/** order by sum() on columns of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Sum_Order_By = {
  vote_count?: Maybe<Order_By>,
};

/** aggregate var_pop on columns */
export type Poll_Submission_Vote_Rankings_Var_Pop_Fields = {
   __typename?: 'poll_submission_vote_rankings_var_pop_fields',
  vote_count?: Maybe<Scalars['Float']>,
};

/** order by var_pop() on columns of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Var_Pop_Order_By = {
  vote_count?: Maybe<Order_By>,
};

/** aggregate var_samp on columns */
export type Poll_Submission_Vote_Rankings_Var_Samp_Fields = {
   __typename?: 'poll_submission_vote_rankings_var_samp_fields',
  vote_count?: Maybe<Scalars['Float']>,
};

/** order by var_samp() on columns of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Var_Samp_Order_By = {
  vote_count?: Maybe<Order_By>,
};

/** aggregate variance on columns */
export type Poll_Submission_Vote_Rankings_Variance_Fields = {
   __typename?: 'poll_submission_vote_rankings_variance_fields',
  vote_count?: Maybe<Scalars['Float']>,
};

/** order by variance() on columns of table "poll_submission_vote_rankings" */
export type Poll_Submission_Vote_Rankings_Variance_Order_By = {
  vote_count?: Maybe<Order_By>,
};

/** columns and relationships of "polls" */
export type Polls = {
   __typename?: 'polls',
  closed_at?: Maybe<Scalars['timestamptz']>,
  closed_by?: Maybe<Scalars['String']>,
  created_at: Scalars['timestamptz'],
  created_by: Scalars['String'],
  id: Scalars['String'],
  is_open: Scalars['Boolean'],
  opened_at: Scalars['timestamptz'],
  /** An array relationship */
  poll_submission_options: Array<Poll_Submission_Options>,
  /** An aggregated array relationship */
  poll_submission_options_aggregate: Poll_Submission_Options_Aggregate,
  /** An array relationship */
  rankings: Array<Poll_Submission_Vote_Rankings>,
  /** An aggregated array relationship */
  rankings_aggregate: Poll_Submission_Vote_Rankings_Aggregate,
  /** An array relationship */
  recommendations: Array<User_Submitter_Recommendations>,
  /** An aggregated array relationship */
  recommendations_aggregate: User_Submitter_Recommendations_Aggregate,
  updated_at: Scalars['timestamptz'],
  /** An array relationship */
  votes: Array<Votes>,
  /** An aggregated array relationship */
  votes_aggregate: Votes_Aggregate,
};


/** columns and relationships of "polls" */
export type PollsPoll_Submission_OptionsArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Options_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Options_Order_By>>,
  where?: Maybe<Poll_Submission_Options_Bool_Exp>
};


/** columns and relationships of "polls" */
export type PollsPoll_Submission_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Options_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Options_Order_By>>,
  where?: Maybe<Poll_Submission_Options_Bool_Exp>
};


/** columns and relationships of "polls" */
export type PollsRankingsArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Vote_Rankings_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Vote_Rankings_Order_By>>,
  where?: Maybe<Poll_Submission_Vote_Rankings_Bool_Exp>
};


/** columns and relationships of "polls" */
export type PollsRankings_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Vote_Rankings_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Vote_Rankings_Order_By>>,
  where?: Maybe<Poll_Submission_Vote_Rankings_Bool_Exp>
};


/** columns and relationships of "polls" */
export type PollsRecommendationsArgs = {
  distinct_on?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<User_Submitter_Recommendations_Order_By>>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>
};


/** columns and relationships of "polls" */
export type PollsRecommendations_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<User_Submitter_Recommendations_Order_By>>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>
};


/** columns and relationships of "polls" */
export type PollsVotesArgs = {
  distinct_on?: Maybe<Array<Votes_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Votes_Order_By>>,
  where?: Maybe<Votes_Bool_Exp>
};


/** columns and relationships of "polls" */
export type PollsVotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Votes_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Votes_Order_By>>,
  where?: Maybe<Votes_Bool_Exp>
};

/** aggregated selection of "polls" */
export type Polls_Aggregate = {
   __typename?: 'polls_aggregate',
  aggregate?: Maybe<Polls_Aggregate_Fields>,
  nodes: Array<Polls>,
};

/** aggregate fields of "polls" */
export type Polls_Aggregate_Fields = {
   __typename?: 'polls_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<Polls_Max_Fields>,
  min?: Maybe<Polls_Min_Fields>,
};


/** aggregate fields of "polls" */
export type Polls_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Polls_Select_Column>>,
  distinct?: Maybe<Scalars['Boolean']>
};

/** order by aggregate values of table "polls" */
export type Polls_Aggregate_Order_By = {
  count?: Maybe<Order_By>,
  max?: Maybe<Polls_Max_Order_By>,
  min?: Maybe<Polls_Min_Order_By>,
};

/** input type for inserting array relation for remote table "polls" */
export type Polls_Arr_Rel_Insert_Input = {
  data: Array<Polls_Insert_Input>,
  on_conflict?: Maybe<Polls_On_Conflict>,
};

/** Boolean expression to filter rows from the table "polls". All fields are combined with a logical 'AND'. */
export type Polls_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Polls_Bool_Exp>>>,
  _not?: Maybe<Polls_Bool_Exp>,
  _or?: Maybe<Array<Maybe<Polls_Bool_Exp>>>,
  closed_at?: Maybe<Timestamptz_Comparison_Exp>,
  closed_by?: Maybe<String_Comparison_Exp>,
  created_at?: Maybe<Timestamptz_Comparison_Exp>,
  created_by?: Maybe<String_Comparison_Exp>,
  id?: Maybe<String_Comparison_Exp>,
  is_open?: Maybe<Boolean_Comparison_Exp>,
  opened_at?: Maybe<Timestamptz_Comparison_Exp>,
  poll_submission_options?: Maybe<Poll_Submission_Options_Bool_Exp>,
  rankings?: Maybe<Poll_Submission_Vote_Rankings_Bool_Exp>,
  recommendations?: Maybe<User_Submitter_Recommendations_Bool_Exp>,
  updated_at?: Maybe<Timestamptz_Comparison_Exp>,
  votes?: Maybe<Votes_Bool_Exp>,
};

/** unique or primary key constraints on table "polls" */
export enum Polls_Constraint {
  /** unique or primary key constraint */
  PollsPkey = 'polls_pkey'
}

/** input type for inserting data into table "polls" */
export type Polls_Insert_Input = {
  closed_at?: Maybe<Scalars['timestamptz']>,
  closed_by?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_open?: Maybe<Scalars['Boolean']>,
  opened_at?: Maybe<Scalars['timestamptz']>,
  poll_submission_options?: Maybe<Poll_Submission_Options_Arr_Rel_Insert_Input>,
  recommendations?: Maybe<User_Submitter_Recommendations_Arr_Rel_Insert_Input>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  votes?: Maybe<Votes_Arr_Rel_Insert_Input>,
};

/** aggregate max on columns */
export type Polls_Max_Fields = {
   __typename?: 'polls_max_fields',
  closed_at?: Maybe<Scalars['timestamptz']>,
  closed_by?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  opened_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
};

/** order by max() on columns of table "polls" */
export type Polls_Max_Order_By = {
  closed_at?: Maybe<Order_By>,
  closed_by?: Maybe<Order_By>,
  created_at?: Maybe<Order_By>,
  created_by?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  opened_at?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
};

/** aggregate min on columns */
export type Polls_Min_Fields = {
   __typename?: 'polls_min_fields',
  closed_at?: Maybe<Scalars['timestamptz']>,
  closed_by?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  opened_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
};

/** order by min() on columns of table "polls" */
export type Polls_Min_Order_By = {
  closed_at?: Maybe<Order_By>,
  closed_by?: Maybe<Order_By>,
  created_at?: Maybe<Order_By>,
  created_by?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  opened_at?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
};

/** response of any mutation on the table "polls" */
export type Polls_Mutation_Response = {
   __typename?: 'polls_mutation_response',
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'],
  /** data of the affected rows by the mutation */
  returning: Array<Polls>,
};

/** input type for inserting object relation for remote table "polls" */
export type Polls_Obj_Rel_Insert_Input = {
  data: Polls_Insert_Input,
  on_conflict?: Maybe<Polls_On_Conflict>,
};

/** on conflict condition type for table "polls" */
export type Polls_On_Conflict = {
  constraint: Polls_Constraint,
  update_columns: Array<Polls_Update_Column>,
  where?: Maybe<Polls_Bool_Exp>,
};

/** ordering options when selecting data from "polls" */
export type Polls_Order_By = {
  closed_at?: Maybe<Order_By>,
  closed_by?: Maybe<Order_By>,
  created_at?: Maybe<Order_By>,
  created_by?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  is_open?: Maybe<Order_By>,
  opened_at?: Maybe<Order_By>,
  poll_submission_options_aggregate?: Maybe<Poll_Submission_Options_Aggregate_Order_By>,
  rankings_aggregate?: Maybe<Poll_Submission_Vote_Rankings_Aggregate_Order_By>,
  recommendations_aggregate?: Maybe<User_Submitter_Recommendations_Aggregate_Order_By>,
  updated_at?: Maybe<Order_By>,
  votes_aggregate?: Maybe<Votes_Aggregate_Order_By>,
};

/** select columns of table "polls" */
export enum Polls_Select_Column {
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  ClosedBy = 'closed_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsOpen = 'is_open',
  /** column name */
  OpenedAt = 'opened_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "polls" */
export type Polls_Set_Input = {
  closed_at?: Maybe<Scalars['timestamptz']>,
  closed_by?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_open?: Maybe<Scalars['Boolean']>,
  opened_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
};

/** update columns of table "polls" */
export enum Polls_Update_Column {
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  ClosedBy = 'closed_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IsOpen = 'is_open',
  /** column name */
  OpenedAt = 'opened_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** query root */
export type Query_Root = {
   __typename?: 'query_root',
  /** fetch data from the table: "online_users" */
  online_users: Array<Online_Users>,
  /** fetch aggregated fields from the table: "online_users" */
  online_users_aggregate: Online_Users_Aggregate,
  /** fetch data from the table: "poll_submission_options" */
  poll_submission_options: Array<Poll_Submission_Options>,
  /** fetch aggregated fields from the table: "poll_submission_options" */
  poll_submission_options_aggregate: Poll_Submission_Options_Aggregate,
  /** fetch data from the table: "poll_submission_options" using primary key columns */
  poll_submission_options_by_pk?: Maybe<Poll_Submission_Options>,
  /** fetch data from the table: "poll_submission_vote_rankings" */
  poll_submission_vote_rankings: Array<Poll_Submission_Vote_Rankings>,
  /** fetch aggregated fields from the table: "poll_submission_vote_rankings" */
  poll_submission_vote_rankings_aggregate: Poll_Submission_Vote_Rankings_Aggregate,
  /** fetch data from the table: "polls" */
  polls: Array<Polls>,
  /** fetch aggregated fields from the table: "polls" */
  polls_aggregate: Polls_Aggregate,
  /** fetch data from the table: "polls" using primary key columns */
  polls_by_pk?: Maybe<Polls>,
  /** fetch data from the table: "submissions" */
  submissions: Array<Submissions>,
  /** fetch aggregated fields from the table: "submissions" */
  submissions_aggregate: Submissions_Aggregate,
  /** fetch data from the table: "submissions" using primary key columns */
  submissions_by_pk?: Maybe<Submissions>,
  /** fetch data from the table: "submitters" */
  submitters: Array<Submitters>,
  /** fetch aggregated fields from the table: "submitters" */
  submitters_aggregate: Submitters_Aggregate,
  /** fetch data from the table: "submitters" using primary key columns */
  submitters_by_pk?: Maybe<Submitters>,
  /** fetch data from the table: "unassigned_submissions" */
  unassigned_submissions: Array<Unassigned_Submissions>,
  /** fetch aggregated fields from the table: "unassigned_submissions" */
  unassigned_submissions_aggregate: Unassigned_Submissions_Aggregate,
  /** fetch data from the table: "user_submitter_recommendations" */
  user_submitter_recommendations: Array<User_Submitter_Recommendations>,
  /** fetch aggregated fields from the table: "user_submitter_recommendations" */
  user_submitter_recommendations_aggregate: User_Submitter_Recommendations_Aggregate,
  /** fetch data from the table: "user_submitter_recommendations" using primary key columns */
  user_submitter_recommendations_by_pk?: Maybe<User_Submitter_Recommendations>,
  /** fetch data from the table: "users" */
  users: Array<Users>,
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate,
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>,
  /** fetch data from the table: "votes" */
  votes: Array<Votes>,
  /** fetch aggregated fields from the table: "votes" */
  votes_aggregate: Votes_Aggregate,
  /** fetch data from the table: "votes" using primary key columns */
  votes_by_pk?: Maybe<Votes>,
};


/** query root */
export type Query_RootOnline_UsersArgs = {
  distinct_on?: Maybe<Array<Online_Users_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Online_Users_Order_By>>,
  where?: Maybe<Online_Users_Bool_Exp>
};


/** query root */
export type Query_RootOnline_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Online_Users_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Online_Users_Order_By>>,
  where?: Maybe<Online_Users_Bool_Exp>
};


/** query root */
export type Query_RootPoll_Submission_OptionsArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Options_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Options_Order_By>>,
  where?: Maybe<Poll_Submission_Options_Bool_Exp>
};


/** query root */
export type Query_RootPoll_Submission_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Options_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Options_Order_By>>,
  where?: Maybe<Poll_Submission_Options_Bool_Exp>
};


/** query root */
export type Query_RootPoll_Submission_Options_By_PkArgs = {
  poll_id: Scalars['String'],
  submission_id: Scalars['String']
};


/** query root */
export type Query_RootPoll_Submission_Vote_RankingsArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Vote_Rankings_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Vote_Rankings_Order_By>>,
  where?: Maybe<Poll_Submission_Vote_Rankings_Bool_Exp>
};


/** query root */
export type Query_RootPoll_Submission_Vote_Rankings_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Vote_Rankings_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Vote_Rankings_Order_By>>,
  where?: Maybe<Poll_Submission_Vote_Rankings_Bool_Exp>
};


/** query root */
export type Query_RootPollsArgs = {
  distinct_on?: Maybe<Array<Polls_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Polls_Order_By>>,
  where?: Maybe<Polls_Bool_Exp>
};


/** query root */
export type Query_RootPolls_AggregateArgs = {
  distinct_on?: Maybe<Array<Polls_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Polls_Order_By>>,
  where?: Maybe<Polls_Bool_Exp>
};


/** query root */
export type Query_RootPolls_By_PkArgs = {
  id: Scalars['String']
};


/** query root */
export type Query_RootSubmissionsArgs = {
  distinct_on?: Maybe<Array<Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submissions_Order_By>>,
  where?: Maybe<Submissions_Bool_Exp>
};


/** query root */
export type Query_RootSubmissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submissions_Order_By>>,
  where?: Maybe<Submissions_Bool_Exp>
};


/** query root */
export type Query_RootSubmissions_By_PkArgs = {
  id: Scalars['String']
};


/** query root */
export type Query_RootSubmittersArgs = {
  distinct_on?: Maybe<Array<Submitters_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submitters_Order_By>>,
  where?: Maybe<Submitters_Bool_Exp>
};


/** query root */
export type Query_RootSubmitters_AggregateArgs = {
  distinct_on?: Maybe<Array<Submitters_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submitters_Order_By>>,
  where?: Maybe<Submitters_Bool_Exp>
};


/** query root */
export type Query_RootSubmitters_By_PkArgs = {
  id: Scalars['String']
};


/** query root */
export type Query_RootUnassigned_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Unassigned_Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Unassigned_Submissions_Order_By>>,
  where?: Maybe<Unassigned_Submissions_Bool_Exp>
};


/** query root */
export type Query_RootUnassigned_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Unassigned_Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Unassigned_Submissions_Order_By>>,
  where?: Maybe<Unassigned_Submissions_Bool_Exp>
};


/** query root */
export type Query_RootUser_Submitter_RecommendationsArgs = {
  distinct_on?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<User_Submitter_Recommendations_Order_By>>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>
};


/** query root */
export type Query_RootUser_Submitter_Recommendations_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<User_Submitter_Recommendations_Order_By>>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>
};


/** query root */
export type Query_RootUser_Submitter_Recommendations_By_PkArgs = {
  id: Scalars['uuid'],
  submitter_id: Scalars['String']
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Users_Order_By>>,
  where?: Maybe<Users_Bool_Exp>
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Users_Order_By>>,
  where?: Maybe<Users_Bool_Exp>
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String']
};


/** query root */
export type Query_RootVotesArgs = {
  distinct_on?: Maybe<Array<Votes_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Votes_Order_By>>,
  where?: Maybe<Votes_Bool_Exp>
};


/** query root */
export type Query_RootVotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Votes_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Votes_Order_By>>,
  where?: Maybe<Votes_Bool_Exp>
};


/** query root */
export type Query_RootVotes_By_PkArgs = {
  poll_id: Scalars['String'],
  submission_id: Scalars['String'],
  user_id: Scalars['String']
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>,
  _gt?: Maybe<Scalars['String']>,
  _gte?: Maybe<Scalars['String']>,
  _ilike?: Maybe<Scalars['String']>,
  _in?: Maybe<Array<Scalars['String']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _like?: Maybe<Scalars['String']>,
  _lt?: Maybe<Scalars['String']>,
  _lte?: Maybe<Scalars['String']>,
  _neq?: Maybe<Scalars['String']>,
  _nilike?: Maybe<Scalars['String']>,
  _nin?: Maybe<Array<Scalars['String']>>,
  _nlike?: Maybe<Scalars['String']>,
  _nsimilar?: Maybe<Scalars['String']>,
  _similar?: Maybe<Scalars['String']>,
};

/** columns and relationships of "submissions" */
export type Submissions = {
   __typename?: 'submissions',
  created_at: Scalars['timestamptz'],
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  ig_image_url?: Maybe<Scalars['String']>,
  ig_video_url?: Maybe<Scalars['String']>,
  image_url?: Maybe<Scalars['String']>,
  is_active: Scalars['Boolean'],
  is_video?: Maybe<Scalars['Boolean']>,
  owner_id?: Maybe<Scalars['String']>,
  /** An array relationship */
  poll_submission_options: Array<Poll_Submission_Options>,
  /** An aggregated array relationship */
  poll_submission_options_aggregate: Poll_Submission_Options_Aggregate,
  shortcode: Scalars['String'],
  /** An object relationship */
  submitter: Submitters,
  /** An object relationship */
  submitter_by_ownerid?: Maybe<Submitters>,
  taken_at: Scalars['timestamptz'],
  title?: Maybe<Scalars['String']>,
  updated_at: Scalars['timestamptz'],
  url: Scalars['String'],
  username: Scalars['String'],
  video_url?: Maybe<Scalars['String']>,
  /** An array relationship */
  votes: Array<Votes>,
  /** An aggregated array relationship */
  votes_aggregate: Votes_Aggregate,
};


/** columns and relationships of "submissions" */
export type SubmissionsPoll_Submission_OptionsArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Options_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Options_Order_By>>,
  where?: Maybe<Poll_Submission_Options_Bool_Exp>
};


/** columns and relationships of "submissions" */
export type SubmissionsPoll_Submission_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Options_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Options_Order_By>>,
  where?: Maybe<Poll_Submission_Options_Bool_Exp>
};


/** columns and relationships of "submissions" */
export type SubmissionsVotesArgs = {
  distinct_on?: Maybe<Array<Votes_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Votes_Order_By>>,
  where?: Maybe<Votes_Bool_Exp>
};


/** columns and relationships of "submissions" */
export type SubmissionsVotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Votes_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Votes_Order_By>>,
  where?: Maybe<Votes_Bool_Exp>
};

/** aggregated selection of "submissions" */
export type Submissions_Aggregate = {
   __typename?: 'submissions_aggregate',
  aggregate?: Maybe<Submissions_Aggregate_Fields>,
  nodes: Array<Submissions>,
};

/** aggregate fields of "submissions" */
export type Submissions_Aggregate_Fields = {
   __typename?: 'submissions_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<Submissions_Max_Fields>,
  min?: Maybe<Submissions_Min_Fields>,
};


/** aggregate fields of "submissions" */
export type Submissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Submissions_Select_Column>>,
  distinct?: Maybe<Scalars['Boolean']>
};

/** order by aggregate values of table "submissions" */
export type Submissions_Aggregate_Order_By = {
  count?: Maybe<Order_By>,
  max?: Maybe<Submissions_Max_Order_By>,
  min?: Maybe<Submissions_Min_Order_By>,
};

/** input type for inserting array relation for remote table "submissions" */
export type Submissions_Arr_Rel_Insert_Input = {
  data: Array<Submissions_Insert_Input>,
  on_conflict?: Maybe<Submissions_On_Conflict>,
};

/** Boolean expression to filter rows from the table "submissions". All fields are combined with a logical 'AND'. */
export type Submissions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Submissions_Bool_Exp>>>,
  _not?: Maybe<Submissions_Bool_Exp>,
  _or?: Maybe<Array<Maybe<Submissions_Bool_Exp>>>,
  created_at?: Maybe<Timestamptz_Comparison_Exp>,
  description?: Maybe<String_Comparison_Exp>,
  id?: Maybe<String_Comparison_Exp>,
  ig_image_url?: Maybe<String_Comparison_Exp>,
  ig_video_url?: Maybe<String_Comparison_Exp>,
  image_url?: Maybe<String_Comparison_Exp>,
  is_active?: Maybe<Boolean_Comparison_Exp>,
  is_video?: Maybe<Boolean_Comparison_Exp>,
  owner_id?: Maybe<String_Comparison_Exp>,
  poll_submission_options?: Maybe<Poll_Submission_Options_Bool_Exp>,
  shortcode?: Maybe<String_Comparison_Exp>,
  submitter?: Maybe<Submitters_Bool_Exp>,
  submitter_by_ownerid?: Maybe<Submitters_Bool_Exp>,
  taken_at?: Maybe<Timestamptz_Comparison_Exp>,
  title?: Maybe<String_Comparison_Exp>,
  updated_at?: Maybe<Timestamptz_Comparison_Exp>,
  url?: Maybe<String_Comparison_Exp>,
  username?: Maybe<String_Comparison_Exp>,
  video_url?: Maybe<String_Comparison_Exp>,
  votes?: Maybe<Votes_Bool_Exp>,
};

/** unique or primary key constraints on table "submissions" */
export enum Submissions_Constraint {
  /** unique or primary key constraint */
  SubmissionsPkey = 'submissions_pkey'
}

/** input type for inserting data into table "submissions" */
export type Submissions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  ig_image_url?: Maybe<Scalars['String']>,
  ig_video_url?: Maybe<Scalars['String']>,
  image_url?: Maybe<Scalars['String']>,
  is_active?: Maybe<Scalars['Boolean']>,
  is_video?: Maybe<Scalars['Boolean']>,
  owner_id?: Maybe<Scalars['String']>,
  poll_submission_options?: Maybe<Poll_Submission_Options_Arr_Rel_Insert_Input>,
  shortcode?: Maybe<Scalars['String']>,
  submitter?: Maybe<Submitters_Obj_Rel_Insert_Input>,
  submitter_by_ownerid?: Maybe<Submitters_Obj_Rel_Insert_Input>,
  taken_at?: Maybe<Scalars['timestamptz']>,
  title?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  video_url?: Maybe<Scalars['String']>,
  votes?: Maybe<Votes_Arr_Rel_Insert_Input>,
};

/** aggregate max on columns */
export type Submissions_Max_Fields = {
   __typename?: 'submissions_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  ig_image_url?: Maybe<Scalars['String']>,
  ig_video_url?: Maybe<Scalars['String']>,
  image_url?: Maybe<Scalars['String']>,
  owner_id?: Maybe<Scalars['String']>,
  shortcode?: Maybe<Scalars['String']>,
  taken_at?: Maybe<Scalars['timestamptz']>,
  title?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  video_url?: Maybe<Scalars['String']>,
};

/** order by max() on columns of table "submissions" */
export type Submissions_Max_Order_By = {
  created_at?: Maybe<Order_By>,
  description?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  ig_image_url?: Maybe<Order_By>,
  ig_video_url?: Maybe<Order_By>,
  image_url?: Maybe<Order_By>,
  owner_id?: Maybe<Order_By>,
  shortcode?: Maybe<Order_By>,
  taken_at?: Maybe<Order_By>,
  title?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  url?: Maybe<Order_By>,
  username?: Maybe<Order_By>,
  video_url?: Maybe<Order_By>,
};

/** aggregate min on columns */
export type Submissions_Min_Fields = {
   __typename?: 'submissions_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  ig_image_url?: Maybe<Scalars['String']>,
  ig_video_url?: Maybe<Scalars['String']>,
  image_url?: Maybe<Scalars['String']>,
  owner_id?: Maybe<Scalars['String']>,
  shortcode?: Maybe<Scalars['String']>,
  taken_at?: Maybe<Scalars['timestamptz']>,
  title?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  video_url?: Maybe<Scalars['String']>,
};

/** order by min() on columns of table "submissions" */
export type Submissions_Min_Order_By = {
  created_at?: Maybe<Order_By>,
  description?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  ig_image_url?: Maybe<Order_By>,
  ig_video_url?: Maybe<Order_By>,
  image_url?: Maybe<Order_By>,
  owner_id?: Maybe<Order_By>,
  shortcode?: Maybe<Order_By>,
  taken_at?: Maybe<Order_By>,
  title?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  url?: Maybe<Order_By>,
  username?: Maybe<Order_By>,
  video_url?: Maybe<Order_By>,
};

/** response of any mutation on the table "submissions" */
export type Submissions_Mutation_Response = {
   __typename?: 'submissions_mutation_response',
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'],
  /** data of the affected rows by the mutation */
  returning: Array<Submissions>,
};

/** input type for inserting object relation for remote table "submissions" */
export type Submissions_Obj_Rel_Insert_Input = {
  data: Submissions_Insert_Input,
  on_conflict?: Maybe<Submissions_On_Conflict>,
};

/** on conflict condition type for table "submissions" */
export type Submissions_On_Conflict = {
  constraint: Submissions_Constraint,
  update_columns: Array<Submissions_Update_Column>,
  where?: Maybe<Submissions_Bool_Exp>,
};

/** ordering options when selecting data from "submissions" */
export type Submissions_Order_By = {
  created_at?: Maybe<Order_By>,
  description?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  ig_image_url?: Maybe<Order_By>,
  ig_video_url?: Maybe<Order_By>,
  image_url?: Maybe<Order_By>,
  is_active?: Maybe<Order_By>,
  is_video?: Maybe<Order_By>,
  owner_id?: Maybe<Order_By>,
  poll_submission_options_aggregate?: Maybe<Poll_Submission_Options_Aggregate_Order_By>,
  shortcode?: Maybe<Order_By>,
  submitter?: Maybe<Submitters_Order_By>,
  submitter_by_ownerid?: Maybe<Submitters_Order_By>,
  taken_at?: Maybe<Order_By>,
  title?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  url?: Maybe<Order_By>,
  username?: Maybe<Order_By>,
  video_url?: Maybe<Order_By>,
  votes_aggregate?: Maybe<Votes_Aggregate_Order_By>,
};

/** select columns of table "submissions" */
export enum Submissions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IgImageUrl = 'ig_image_url',
  /** column name */
  IgVideoUrl = 'ig_video_url',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsVideo = 'is_video',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Shortcode = 'shortcode',
  /** column name */
  TakenAt = 'taken_at',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Username = 'username',
  /** column name */
  VideoUrl = 'video_url'
}

/** input type for updating data in table "submissions" */
export type Submissions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  ig_image_url?: Maybe<Scalars['String']>,
  ig_video_url?: Maybe<Scalars['String']>,
  image_url?: Maybe<Scalars['String']>,
  is_active?: Maybe<Scalars['Boolean']>,
  is_video?: Maybe<Scalars['Boolean']>,
  owner_id?: Maybe<Scalars['String']>,
  shortcode?: Maybe<Scalars['String']>,
  taken_at?: Maybe<Scalars['timestamptz']>,
  title?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  video_url?: Maybe<Scalars['String']>,
};

/** update columns of table "submissions" */
export enum Submissions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IgImageUrl = 'ig_image_url',
  /** column name */
  IgVideoUrl = 'ig_video_url',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsVideo = 'is_video',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Shortcode = 'shortcode',
  /** column name */
  TakenAt = 'taken_at',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Username = 'username',
  /** column name */
  VideoUrl = 'video_url'
}

/** columns and relationships of "submitters" */
export type Submitters = {
   __typename?: 'submitters',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  /** An array relationship */
  submissions_by_id: Array<Submissions>,
  /** An aggregated array relationship */
  submissions_by_id_aggregate: Submissions_Aggregate,
  /** An array relationship */
  submissions_by_username: Array<Submissions>,
  /** An aggregated array relationship */
  submissions_by_username_aggregate: Submissions_Aggregate,
  updated_at: Scalars['timestamptz'],
  /** An array relationship */
  user_submitter_recommendations: Array<User_Submitter_Recommendations>,
  /** An aggregated array relationship */
  user_submitter_recommendations_aggregate: User_Submitter_Recommendations_Aggregate,
  username: Scalars['String'],
};


/** columns and relationships of "submitters" */
export type SubmittersSubmissions_By_IdArgs = {
  distinct_on?: Maybe<Array<Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submissions_Order_By>>,
  where?: Maybe<Submissions_Bool_Exp>
};


/** columns and relationships of "submitters" */
export type SubmittersSubmissions_By_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submissions_Order_By>>,
  where?: Maybe<Submissions_Bool_Exp>
};


/** columns and relationships of "submitters" */
export type SubmittersSubmissions_By_UsernameArgs = {
  distinct_on?: Maybe<Array<Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submissions_Order_By>>,
  where?: Maybe<Submissions_Bool_Exp>
};


/** columns and relationships of "submitters" */
export type SubmittersSubmissions_By_Username_AggregateArgs = {
  distinct_on?: Maybe<Array<Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submissions_Order_By>>,
  where?: Maybe<Submissions_Bool_Exp>
};


/** columns and relationships of "submitters" */
export type SubmittersUser_Submitter_RecommendationsArgs = {
  distinct_on?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<User_Submitter_Recommendations_Order_By>>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>
};


/** columns and relationships of "submitters" */
export type SubmittersUser_Submitter_Recommendations_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<User_Submitter_Recommendations_Order_By>>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>
};

/** aggregated selection of "submitters" */
export type Submitters_Aggregate = {
   __typename?: 'submitters_aggregate',
  aggregate?: Maybe<Submitters_Aggregate_Fields>,
  nodes: Array<Submitters>,
};

/** aggregate fields of "submitters" */
export type Submitters_Aggregate_Fields = {
   __typename?: 'submitters_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<Submitters_Max_Fields>,
  min?: Maybe<Submitters_Min_Fields>,
};


/** aggregate fields of "submitters" */
export type Submitters_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Submitters_Select_Column>>,
  distinct?: Maybe<Scalars['Boolean']>
};

/** order by aggregate values of table "submitters" */
export type Submitters_Aggregate_Order_By = {
  count?: Maybe<Order_By>,
  max?: Maybe<Submitters_Max_Order_By>,
  min?: Maybe<Submitters_Min_Order_By>,
};

/** input type for inserting array relation for remote table "submitters" */
export type Submitters_Arr_Rel_Insert_Input = {
  data: Array<Submitters_Insert_Input>,
  on_conflict?: Maybe<Submitters_On_Conflict>,
};

/** Boolean expression to filter rows from the table "submitters". All fields are combined with a logical 'AND'. */
export type Submitters_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Submitters_Bool_Exp>>>,
  _not?: Maybe<Submitters_Bool_Exp>,
  _or?: Maybe<Array<Maybe<Submitters_Bool_Exp>>>,
  created_at?: Maybe<Timestamptz_Comparison_Exp>,
  id?: Maybe<String_Comparison_Exp>,
  submissions_by_id?: Maybe<Submissions_Bool_Exp>,
  submissions_by_username?: Maybe<Submissions_Bool_Exp>,
  updated_at?: Maybe<Timestamptz_Comparison_Exp>,
  user_submitter_recommendations?: Maybe<User_Submitter_Recommendations_Bool_Exp>,
  username?: Maybe<String_Comparison_Exp>,
};

/** unique or primary key constraints on table "submitters" */
export enum Submitters_Constraint {
  /** unique or primary key constraint */
  SubmittersOwnerIdKey = 'submitters_owner_id_key',
  /** unique or primary key constraint */
  SubmittersPkey = 'submitters_pkey',
  /** unique or primary key constraint */
  SubmittersUsernameKey = 'submitters_username_key'
}

/** input type for inserting data into table "submitters" */
export type Submitters_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  submissions_by_id?: Maybe<Submissions_Arr_Rel_Insert_Input>,
  submissions_by_username?: Maybe<Submissions_Arr_Rel_Insert_Input>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_submitter_recommendations?: Maybe<User_Submitter_Recommendations_Arr_Rel_Insert_Input>,
  username?: Maybe<Scalars['String']>,
};

/** aggregate max on columns */
export type Submitters_Max_Fields = {
   __typename?: 'submitters_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  username?: Maybe<Scalars['String']>,
};

/** order by max() on columns of table "submitters" */
export type Submitters_Max_Order_By = {
  created_at?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  username?: Maybe<Order_By>,
};

/** aggregate min on columns */
export type Submitters_Min_Fields = {
   __typename?: 'submitters_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  username?: Maybe<Scalars['String']>,
};

/** order by min() on columns of table "submitters" */
export type Submitters_Min_Order_By = {
  created_at?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  username?: Maybe<Order_By>,
};

/** response of any mutation on the table "submitters" */
export type Submitters_Mutation_Response = {
   __typename?: 'submitters_mutation_response',
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'],
  /** data of the affected rows by the mutation */
  returning: Array<Submitters>,
};

/** input type for inserting object relation for remote table "submitters" */
export type Submitters_Obj_Rel_Insert_Input = {
  data: Submitters_Insert_Input,
  on_conflict?: Maybe<Submitters_On_Conflict>,
};

/** on conflict condition type for table "submitters" */
export type Submitters_On_Conflict = {
  constraint: Submitters_Constraint,
  update_columns: Array<Submitters_Update_Column>,
  where?: Maybe<Submitters_Bool_Exp>,
};

/** ordering options when selecting data from "submitters" */
export type Submitters_Order_By = {
  created_at?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  submissions_by_id_aggregate?: Maybe<Submissions_Aggregate_Order_By>,
  submissions_by_username_aggregate?: Maybe<Submissions_Aggregate_Order_By>,
  updated_at?: Maybe<Order_By>,
  user_submitter_recommendations_aggregate?: Maybe<User_Submitter_Recommendations_Aggregate_Order_By>,
  username?: Maybe<Order_By>,
};

/** select columns of table "submitters" */
export enum Submitters_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "submitters" */
export type Submitters_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  username?: Maybe<Scalars['String']>,
};

/** update columns of table "submitters" */
export enum Submitters_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** subscription root */
export type Subscription_Root = {
   __typename?: 'subscription_root',
  /** fetch data from the table: "online_users" */
  online_users: Array<Online_Users>,
  /** fetch aggregated fields from the table: "online_users" */
  online_users_aggregate: Online_Users_Aggregate,
  /** fetch data from the table: "poll_submission_options" */
  poll_submission_options: Array<Poll_Submission_Options>,
  /** fetch aggregated fields from the table: "poll_submission_options" */
  poll_submission_options_aggregate: Poll_Submission_Options_Aggregate,
  /** fetch data from the table: "poll_submission_options" using primary key columns */
  poll_submission_options_by_pk?: Maybe<Poll_Submission_Options>,
  /** fetch data from the table: "poll_submission_vote_rankings" */
  poll_submission_vote_rankings: Array<Poll_Submission_Vote_Rankings>,
  /** fetch aggregated fields from the table: "poll_submission_vote_rankings" */
  poll_submission_vote_rankings_aggregate: Poll_Submission_Vote_Rankings_Aggregate,
  /** fetch data from the table: "polls" */
  polls: Array<Polls>,
  /** fetch aggregated fields from the table: "polls" */
  polls_aggregate: Polls_Aggregate,
  /** fetch data from the table: "polls" using primary key columns */
  polls_by_pk?: Maybe<Polls>,
  /** fetch data from the table: "submissions" */
  submissions: Array<Submissions>,
  /** fetch aggregated fields from the table: "submissions" */
  submissions_aggregate: Submissions_Aggregate,
  /** fetch data from the table: "submissions" using primary key columns */
  submissions_by_pk?: Maybe<Submissions>,
  /** fetch data from the table: "submitters" */
  submitters: Array<Submitters>,
  /** fetch aggregated fields from the table: "submitters" */
  submitters_aggregate: Submitters_Aggregate,
  /** fetch data from the table: "submitters" using primary key columns */
  submitters_by_pk?: Maybe<Submitters>,
  /** fetch data from the table: "unassigned_submissions" */
  unassigned_submissions: Array<Unassigned_Submissions>,
  /** fetch aggregated fields from the table: "unassigned_submissions" */
  unassigned_submissions_aggregate: Unassigned_Submissions_Aggregate,
  /** fetch data from the table: "user_submitter_recommendations" */
  user_submitter_recommendations: Array<User_Submitter_Recommendations>,
  /** fetch aggregated fields from the table: "user_submitter_recommendations" */
  user_submitter_recommendations_aggregate: User_Submitter_Recommendations_Aggregate,
  /** fetch data from the table: "user_submitter_recommendations" using primary key columns */
  user_submitter_recommendations_by_pk?: Maybe<User_Submitter_Recommendations>,
  /** fetch data from the table: "users" */
  users: Array<Users>,
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate,
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>,
  /** fetch data from the table: "votes" */
  votes: Array<Votes>,
  /** fetch aggregated fields from the table: "votes" */
  votes_aggregate: Votes_Aggregate,
  /** fetch data from the table: "votes" using primary key columns */
  votes_by_pk?: Maybe<Votes>,
};


/** subscription root */
export type Subscription_RootOnline_UsersArgs = {
  distinct_on?: Maybe<Array<Online_Users_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Online_Users_Order_By>>,
  where?: Maybe<Online_Users_Bool_Exp>
};


/** subscription root */
export type Subscription_RootOnline_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Online_Users_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Online_Users_Order_By>>,
  where?: Maybe<Online_Users_Bool_Exp>
};


/** subscription root */
export type Subscription_RootPoll_Submission_OptionsArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Options_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Options_Order_By>>,
  where?: Maybe<Poll_Submission_Options_Bool_Exp>
};


/** subscription root */
export type Subscription_RootPoll_Submission_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Options_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Options_Order_By>>,
  where?: Maybe<Poll_Submission_Options_Bool_Exp>
};


/** subscription root */
export type Subscription_RootPoll_Submission_Options_By_PkArgs = {
  poll_id: Scalars['String'],
  submission_id: Scalars['String']
};


/** subscription root */
export type Subscription_RootPoll_Submission_Vote_RankingsArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Vote_Rankings_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Vote_Rankings_Order_By>>,
  where?: Maybe<Poll_Submission_Vote_Rankings_Bool_Exp>
};


/** subscription root */
export type Subscription_RootPoll_Submission_Vote_Rankings_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Submission_Vote_Rankings_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Poll_Submission_Vote_Rankings_Order_By>>,
  where?: Maybe<Poll_Submission_Vote_Rankings_Bool_Exp>
};


/** subscription root */
export type Subscription_RootPollsArgs = {
  distinct_on?: Maybe<Array<Polls_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Polls_Order_By>>,
  where?: Maybe<Polls_Bool_Exp>
};


/** subscription root */
export type Subscription_RootPolls_AggregateArgs = {
  distinct_on?: Maybe<Array<Polls_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Polls_Order_By>>,
  where?: Maybe<Polls_Bool_Exp>
};


/** subscription root */
export type Subscription_RootPolls_By_PkArgs = {
  id: Scalars['String']
};


/** subscription root */
export type Subscription_RootSubmissionsArgs = {
  distinct_on?: Maybe<Array<Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submissions_Order_By>>,
  where?: Maybe<Submissions_Bool_Exp>
};


/** subscription root */
export type Subscription_RootSubmissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submissions_Order_By>>,
  where?: Maybe<Submissions_Bool_Exp>
};


/** subscription root */
export type Subscription_RootSubmissions_By_PkArgs = {
  id: Scalars['String']
};


/** subscription root */
export type Subscription_RootSubmittersArgs = {
  distinct_on?: Maybe<Array<Submitters_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submitters_Order_By>>,
  where?: Maybe<Submitters_Bool_Exp>
};


/** subscription root */
export type Subscription_RootSubmitters_AggregateArgs = {
  distinct_on?: Maybe<Array<Submitters_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Submitters_Order_By>>,
  where?: Maybe<Submitters_Bool_Exp>
};


/** subscription root */
export type Subscription_RootSubmitters_By_PkArgs = {
  id: Scalars['String']
};


/** subscription root */
export type Subscription_RootUnassigned_SubmissionsArgs = {
  distinct_on?: Maybe<Array<Unassigned_Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Unassigned_Submissions_Order_By>>,
  where?: Maybe<Unassigned_Submissions_Bool_Exp>
};


/** subscription root */
export type Subscription_RootUnassigned_Submissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Unassigned_Submissions_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Unassigned_Submissions_Order_By>>,
  where?: Maybe<Unassigned_Submissions_Bool_Exp>
};


/** subscription root */
export type Subscription_RootUser_Submitter_RecommendationsArgs = {
  distinct_on?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<User_Submitter_Recommendations_Order_By>>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>
};


/** subscription root */
export type Subscription_RootUser_Submitter_Recommendations_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<User_Submitter_Recommendations_Order_By>>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>
};


/** subscription root */
export type Subscription_RootUser_Submitter_Recommendations_By_PkArgs = {
  id: Scalars['uuid'],
  submitter_id: Scalars['String']
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Users_Order_By>>,
  where?: Maybe<Users_Bool_Exp>
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Users_Order_By>>,
  where?: Maybe<Users_Bool_Exp>
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String']
};


/** subscription root */
export type Subscription_RootVotesArgs = {
  distinct_on?: Maybe<Array<Votes_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Votes_Order_By>>,
  where?: Maybe<Votes_Bool_Exp>
};


/** subscription root */
export type Subscription_RootVotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Votes_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Votes_Order_By>>,
  where?: Maybe<Votes_Bool_Exp>
};


/** subscription root */
export type Subscription_RootVotes_By_PkArgs = {
  poll_id: Scalars['String'],
  submission_id: Scalars['String'],
  user_id: Scalars['String']
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>,
  _gt?: Maybe<Scalars['timestamptz']>,
  _gte?: Maybe<Scalars['timestamptz']>,
  _in?: Maybe<Array<Scalars['timestamptz']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['timestamptz']>,
  _lte?: Maybe<Scalars['timestamptz']>,
  _neq?: Maybe<Scalars['timestamptz']>,
  _nin?: Maybe<Array<Scalars['timestamptz']>>,
};

/** columns and relationships of "unassigned_submissions" */
export type Unassigned_Submissions = {
   __typename?: 'unassigned_submissions',
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  ig_image_url?: Maybe<Scalars['String']>,
  ig_video_url?: Maybe<Scalars['String']>,
  image_url?: Maybe<Scalars['String']>,
  is_active?: Maybe<Scalars['Boolean']>,
  is_video?: Maybe<Scalars['Boolean']>,
  owner_id?: Maybe<Scalars['String']>,
  shortcode?: Maybe<Scalars['String']>,
  taken_at?: Maybe<Scalars['timestamptz']>,
  title?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  video_url?: Maybe<Scalars['String']>,
};

/** aggregated selection of "unassigned_submissions" */
export type Unassigned_Submissions_Aggregate = {
   __typename?: 'unassigned_submissions_aggregate',
  aggregate?: Maybe<Unassigned_Submissions_Aggregate_Fields>,
  nodes: Array<Unassigned_Submissions>,
};

/** aggregate fields of "unassigned_submissions" */
export type Unassigned_Submissions_Aggregate_Fields = {
   __typename?: 'unassigned_submissions_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<Unassigned_Submissions_Max_Fields>,
  min?: Maybe<Unassigned_Submissions_Min_Fields>,
};


/** aggregate fields of "unassigned_submissions" */
export type Unassigned_Submissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Unassigned_Submissions_Select_Column>>,
  distinct?: Maybe<Scalars['Boolean']>
};

/** order by aggregate values of table "unassigned_submissions" */
export type Unassigned_Submissions_Aggregate_Order_By = {
  count?: Maybe<Order_By>,
  max?: Maybe<Unassigned_Submissions_Max_Order_By>,
  min?: Maybe<Unassigned_Submissions_Min_Order_By>,
};

/** input type for inserting array relation for remote table "unassigned_submissions" */
export type Unassigned_Submissions_Arr_Rel_Insert_Input = {
  data: Array<Unassigned_Submissions_Insert_Input>,
};

/** Boolean expression to filter rows from the table "unassigned_submissions". All fields are combined with a logical 'AND'. */
export type Unassigned_Submissions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Unassigned_Submissions_Bool_Exp>>>,
  _not?: Maybe<Unassigned_Submissions_Bool_Exp>,
  _or?: Maybe<Array<Maybe<Unassigned_Submissions_Bool_Exp>>>,
  created_at?: Maybe<Timestamptz_Comparison_Exp>,
  description?: Maybe<String_Comparison_Exp>,
  id?: Maybe<String_Comparison_Exp>,
  ig_image_url?: Maybe<String_Comparison_Exp>,
  ig_video_url?: Maybe<String_Comparison_Exp>,
  image_url?: Maybe<String_Comparison_Exp>,
  is_active?: Maybe<Boolean_Comparison_Exp>,
  is_video?: Maybe<Boolean_Comparison_Exp>,
  owner_id?: Maybe<String_Comparison_Exp>,
  shortcode?: Maybe<String_Comparison_Exp>,
  taken_at?: Maybe<Timestamptz_Comparison_Exp>,
  title?: Maybe<String_Comparison_Exp>,
  updated_at?: Maybe<Timestamptz_Comparison_Exp>,
  url?: Maybe<String_Comparison_Exp>,
  username?: Maybe<String_Comparison_Exp>,
  video_url?: Maybe<String_Comparison_Exp>,
};

/** input type for inserting data into table "unassigned_submissions" */
export type Unassigned_Submissions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  ig_image_url?: Maybe<Scalars['String']>,
  ig_video_url?: Maybe<Scalars['String']>,
  image_url?: Maybe<Scalars['String']>,
  is_active?: Maybe<Scalars['Boolean']>,
  is_video?: Maybe<Scalars['Boolean']>,
  owner_id?: Maybe<Scalars['String']>,
  shortcode?: Maybe<Scalars['String']>,
  taken_at?: Maybe<Scalars['timestamptz']>,
  title?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  video_url?: Maybe<Scalars['String']>,
};

/** aggregate max on columns */
export type Unassigned_Submissions_Max_Fields = {
   __typename?: 'unassigned_submissions_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  ig_image_url?: Maybe<Scalars['String']>,
  ig_video_url?: Maybe<Scalars['String']>,
  image_url?: Maybe<Scalars['String']>,
  owner_id?: Maybe<Scalars['String']>,
  shortcode?: Maybe<Scalars['String']>,
  taken_at?: Maybe<Scalars['timestamptz']>,
  title?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  video_url?: Maybe<Scalars['String']>,
};

/** order by max() on columns of table "unassigned_submissions" */
export type Unassigned_Submissions_Max_Order_By = {
  created_at?: Maybe<Order_By>,
  description?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  ig_image_url?: Maybe<Order_By>,
  ig_video_url?: Maybe<Order_By>,
  image_url?: Maybe<Order_By>,
  owner_id?: Maybe<Order_By>,
  shortcode?: Maybe<Order_By>,
  taken_at?: Maybe<Order_By>,
  title?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  url?: Maybe<Order_By>,
  username?: Maybe<Order_By>,
  video_url?: Maybe<Order_By>,
};

/** aggregate min on columns */
export type Unassigned_Submissions_Min_Fields = {
   __typename?: 'unassigned_submissions_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  ig_image_url?: Maybe<Scalars['String']>,
  ig_video_url?: Maybe<Scalars['String']>,
  image_url?: Maybe<Scalars['String']>,
  owner_id?: Maybe<Scalars['String']>,
  shortcode?: Maybe<Scalars['String']>,
  taken_at?: Maybe<Scalars['timestamptz']>,
  title?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  video_url?: Maybe<Scalars['String']>,
};

/** order by min() on columns of table "unassigned_submissions" */
export type Unassigned_Submissions_Min_Order_By = {
  created_at?: Maybe<Order_By>,
  description?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  ig_image_url?: Maybe<Order_By>,
  ig_video_url?: Maybe<Order_By>,
  image_url?: Maybe<Order_By>,
  owner_id?: Maybe<Order_By>,
  shortcode?: Maybe<Order_By>,
  taken_at?: Maybe<Order_By>,
  title?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  url?: Maybe<Order_By>,
  username?: Maybe<Order_By>,
  video_url?: Maybe<Order_By>,
};

/** response of any mutation on the table "unassigned_submissions" */
export type Unassigned_Submissions_Mutation_Response = {
   __typename?: 'unassigned_submissions_mutation_response',
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'],
  /** data of the affected rows by the mutation */
  returning: Array<Unassigned_Submissions>,
};

/** input type for inserting object relation for remote table "unassigned_submissions" */
export type Unassigned_Submissions_Obj_Rel_Insert_Input = {
  data: Unassigned_Submissions_Insert_Input,
};

/** ordering options when selecting data from "unassigned_submissions" */
export type Unassigned_Submissions_Order_By = {
  created_at?: Maybe<Order_By>,
  description?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  ig_image_url?: Maybe<Order_By>,
  ig_video_url?: Maybe<Order_By>,
  image_url?: Maybe<Order_By>,
  is_active?: Maybe<Order_By>,
  is_video?: Maybe<Order_By>,
  owner_id?: Maybe<Order_By>,
  shortcode?: Maybe<Order_By>,
  taken_at?: Maybe<Order_By>,
  title?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  url?: Maybe<Order_By>,
  username?: Maybe<Order_By>,
  video_url?: Maybe<Order_By>,
};

/** select columns of table "unassigned_submissions" */
export enum Unassigned_Submissions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IgImageUrl = 'ig_image_url',
  /** column name */
  IgVideoUrl = 'ig_video_url',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsVideo = 'is_video',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Shortcode = 'shortcode',
  /** column name */
  TakenAt = 'taken_at',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Username = 'username',
  /** column name */
  VideoUrl = 'video_url'
}

/** input type for updating data in table "unassigned_submissions" */
export type Unassigned_Submissions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  ig_image_url?: Maybe<Scalars['String']>,
  ig_video_url?: Maybe<Scalars['String']>,
  image_url?: Maybe<Scalars['String']>,
  is_active?: Maybe<Scalars['Boolean']>,
  is_video?: Maybe<Scalars['Boolean']>,
  owner_id?: Maybe<Scalars['String']>,
  shortcode?: Maybe<Scalars['String']>,
  taken_at?: Maybe<Scalars['timestamptz']>,
  title?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  video_url?: Maybe<Scalars['String']>,
};

/** columns and relationships of "user_submitter_recommendations" */
export type User_Submitter_Recommendations = {
   __typename?: 'user_submitter_recommendations',
  body?: Maybe<Scalars['String']>,
  created_at: Scalars['timestamptz'],
  id: Scalars['uuid'],
  is_resolved?: Maybe<Scalars['Boolean']>,
  office?: Maybe<Scalars['String']>,
  poll_id?: Maybe<Scalars['String']>,
  /** An object relationship */
  submitter: Submitters,
  submitter_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  /** An object relationship */
  user: Users,
  user_id: Scalars['String'],
};

/** aggregated selection of "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Aggregate = {
   __typename?: 'user_submitter_recommendations_aggregate',
  aggregate?: Maybe<User_Submitter_Recommendations_Aggregate_Fields>,
  nodes: Array<User_Submitter_Recommendations>,
};

/** aggregate fields of "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Aggregate_Fields = {
   __typename?: 'user_submitter_recommendations_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<User_Submitter_Recommendations_Max_Fields>,
  min?: Maybe<User_Submitter_Recommendations_Min_Fields>,
};


/** aggregate fields of "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  distinct?: Maybe<Scalars['Boolean']>
};

/** order by aggregate values of table "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Aggregate_Order_By = {
  count?: Maybe<Order_By>,
  max?: Maybe<User_Submitter_Recommendations_Max_Order_By>,
  min?: Maybe<User_Submitter_Recommendations_Min_Order_By>,
};

/** input type for inserting array relation for remote table "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Arr_Rel_Insert_Input = {
  data: Array<User_Submitter_Recommendations_Insert_Input>,
  on_conflict?: Maybe<User_Submitter_Recommendations_On_Conflict>,
};

/** 
 * Boolean expression to filter rows from the table
 * "user_submitter_recommendations". All fields are combined with a logical 'AND'.
 **/
export type User_Submitter_Recommendations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Submitter_Recommendations_Bool_Exp>>>,
  _not?: Maybe<User_Submitter_Recommendations_Bool_Exp>,
  _or?: Maybe<Array<Maybe<User_Submitter_Recommendations_Bool_Exp>>>,
  body?: Maybe<String_Comparison_Exp>,
  created_at?: Maybe<Timestamptz_Comparison_Exp>,
  id?: Maybe<Uuid_Comparison_Exp>,
  is_resolved?: Maybe<Boolean_Comparison_Exp>,
  office?: Maybe<String_Comparison_Exp>,
  poll_id?: Maybe<String_Comparison_Exp>,
  submitter?: Maybe<Submitters_Bool_Exp>,
  submitter_id?: Maybe<String_Comparison_Exp>,
  updated_at?: Maybe<Timestamptz_Comparison_Exp>,
  user?: Maybe<Users_Bool_Exp>,
  user_id?: Maybe<String_Comparison_Exp>,
};

/** unique or primary key constraints on table "user_submitter_recommendations" */
export enum User_Submitter_Recommendations_Constraint {
  /** unique or primary key constraint */
  UserSubmitterRecommendationsPkey = 'user_submitter_recommendations_pkey'
}

/** input type for inserting data into table "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Insert_Input = {
  body?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['uuid']>,
  is_resolved?: Maybe<Scalars['Boolean']>,
  office?: Maybe<Scalars['String']>,
  poll_id?: Maybe<Scalars['String']>,
  submitter?: Maybe<Submitters_Obj_Rel_Insert_Input>,
  submitter_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user?: Maybe<Users_Obj_Rel_Insert_Input>,
  user_id?: Maybe<Scalars['String']>,
};

/** aggregate max on columns */
export type User_Submitter_Recommendations_Max_Fields = {
   __typename?: 'user_submitter_recommendations_max_fields',
  body?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  office?: Maybe<Scalars['String']>,
  poll_id?: Maybe<Scalars['String']>,
  submitter_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_id?: Maybe<Scalars['String']>,
};

/** order by max() on columns of table "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Max_Order_By = {
  body?: Maybe<Order_By>,
  created_at?: Maybe<Order_By>,
  office?: Maybe<Order_By>,
  poll_id?: Maybe<Order_By>,
  submitter_id?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  user_id?: Maybe<Order_By>,
};

/** aggregate min on columns */
export type User_Submitter_Recommendations_Min_Fields = {
   __typename?: 'user_submitter_recommendations_min_fields',
  body?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  office?: Maybe<Scalars['String']>,
  poll_id?: Maybe<Scalars['String']>,
  submitter_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_id?: Maybe<Scalars['String']>,
};

/** order by min() on columns of table "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Min_Order_By = {
  body?: Maybe<Order_By>,
  created_at?: Maybe<Order_By>,
  office?: Maybe<Order_By>,
  poll_id?: Maybe<Order_By>,
  submitter_id?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  user_id?: Maybe<Order_By>,
};

/** response of any mutation on the table "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Mutation_Response = {
   __typename?: 'user_submitter_recommendations_mutation_response',
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'],
  /** data of the affected rows by the mutation */
  returning: Array<User_Submitter_Recommendations>,
};

/** input type for inserting object relation for remote table "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Obj_Rel_Insert_Input = {
  data: User_Submitter_Recommendations_Insert_Input,
  on_conflict?: Maybe<User_Submitter_Recommendations_On_Conflict>,
};

/** on conflict condition type for table "user_submitter_recommendations" */
export type User_Submitter_Recommendations_On_Conflict = {
  constraint: User_Submitter_Recommendations_Constraint,
  update_columns: Array<User_Submitter_Recommendations_Update_Column>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>,
};

/** ordering options when selecting data from "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Order_By = {
  body?: Maybe<Order_By>,
  created_at?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  is_resolved?: Maybe<Order_By>,
  office?: Maybe<Order_By>,
  poll_id?: Maybe<Order_By>,
  submitter?: Maybe<Submitters_Order_By>,
  submitter_id?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  user?: Maybe<Users_Order_By>,
  user_id?: Maybe<Order_By>,
};

/** select columns of table "user_submitter_recommendations" */
export enum User_Submitter_Recommendations_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsResolved = 'is_resolved',
  /** column name */
  Office = 'office',
  /** column name */
  PollId = 'poll_id',
  /** column name */
  SubmitterId = 'submitter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_submitter_recommendations" */
export type User_Submitter_Recommendations_Set_Input = {
  body?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['uuid']>,
  is_resolved?: Maybe<Scalars['Boolean']>,
  office?: Maybe<Scalars['String']>,
  poll_id?: Maybe<Scalars['String']>,
  submitter_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_id?: Maybe<Scalars['String']>,
};

/** update columns of table "user_submitter_recommendations" */
export enum User_Submitter_Recommendations_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsResolved = 'is_resolved',
  /** column name */
  Office = 'office',
  /** column name */
  PollId = 'poll_id',
  /** column name */
  SubmitterId = 'submitter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "users" */
export type Users = {
   __typename?: 'users',
  created_at: Scalars['timestamptz'],
  email: Scalars['String'],
  first_name: Scalars['String'],
  id: Scalars['String'],
  is_editor: Scalars['Boolean'],
  last_name: Scalars['String'],
  last_seen_at?: Maybe<Scalars['timestamptz']>,
  updated_at: Scalars['timestamptz'],
  /** An array relationship */
  user_submitter_recommendations: Array<User_Submitter_Recommendations>,
  /** An aggregated array relationship */
  user_submitter_recommendations_aggregate: User_Submitter_Recommendations_Aggregate,
  /** An array relationship */
  votes: Array<Votes>,
  /** An aggregated array relationship */
  votes_aggregate: Votes_Aggregate,
};


/** columns and relationships of "users" */
export type UsersUser_Submitter_RecommendationsArgs = {
  distinct_on?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<User_Submitter_Recommendations_Order_By>>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>
};


/** columns and relationships of "users" */
export type UsersUser_Submitter_Recommendations_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Submitter_Recommendations_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<User_Submitter_Recommendations_Order_By>>,
  where?: Maybe<User_Submitter_Recommendations_Bool_Exp>
};


/** columns and relationships of "users" */
export type UsersVotesArgs = {
  distinct_on?: Maybe<Array<Votes_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Votes_Order_By>>,
  where?: Maybe<Votes_Bool_Exp>
};


/** columns and relationships of "users" */
export type UsersVotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Votes_Select_Column>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<Votes_Order_By>>,
  where?: Maybe<Votes_Bool_Exp>
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
   __typename?: 'users_aggregate',
  aggregate?: Maybe<Users_Aggregate_Fields>,
  nodes: Array<Users>,
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
   __typename?: 'users_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<Users_Max_Fields>,
  min?: Maybe<Users_Min_Fields>,
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>,
  distinct?: Maybe<Scalars['Boolean']>
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>,
  max?: Maybe<Users_Max_Order_By>,
  min?: Maybe<Users_Min_Order_By>,
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>,
  on_conflict?: Maybe<Users_On_Conflict>,
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>,
  _not?: Maybe<Users_Bool_Exp>,
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>,
  created_at?: Maybe<Timestamptz_Comparison_Exp>,
  email?: Maybe<String_Comparison_Exp>,
  first_name?: Maybe<String_Comparison_Exp>,
  id?: Maybe<String_Comparison_Exp>,
  is_editor?: Maybe<Boolean_Comparison_Exp>,
  last_name?: Maybe<String_Comparison_Exp>,
  last_seen_at?: Maybe<Timestamptz_Comparison_Exp>,
  updated_at?: Maybe<Timestamptz_Comparison_Exp>,
  user_submitter_recommendations?: Maybe<User_Submitter_Recommendations_Bool_Exp>,
  votes?: Maybe<Votes_Bool_Exp>,
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  email?: Maybe<Scalars['String']>,
  first_name?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_editor?: Maybe<Scalars['Boolean']>,
  last_name?: Maybe<Scalars['String']>,
  last_seen_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_submitter_recommendations?: Maybe<User_Submitter_Recommendations_Arr_Rel_Insert_Input>,
  votes?: Maybe<Votes_Arr_Rel_Insert_Input>,
};

/** aggregate max on columns */
export type Users_Max_Fields = {
   __typename?: 'users_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  email?: Maybe<Scalars['String']>,
  first_name?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  last_name?: Maybe<Scalars['String']>,
  last_seen_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>,
  email?: Maybe<Order_By>,
  first_name?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  last_name?: Maybe<Order_By>,
  last_seen_at?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
};

/** aggregate min on columns */
export type Users_Min_Fields = {
   __typename?: 'users_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  email?: Maybe<Scalars['String']>,
  first_name?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  last_name?: Maybe<Scalars['String']>,
  last_seen_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>,
  email?: Maybe<Order_By>,
  first_name?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  last_name?: Maybe<Order_By>,
  last_seen_at?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
   __typename?: 'users_mutation_response',
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'],
  /** data of the affected rows by the mutation */
  returning: Array<Users>,
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input,
  on_conflict?: Maybe<Users_On_Conflict>,
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint,
  update_columns: Array<Users_Update_Column>,
  where?: Maybe<Users_Bool_Exp>,
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  created_at?: Maybe<Order_By>,
  email?: Maybe<Order_By>,
  first_name?: Maybe<Order_By>,
  id?: Maybe<Order_By>,
  is_editor?: Maybe<Order_By>,
  last_name?: Maybe<Order_By>,
  last_seen_at?: Maybe<Order_By>,
  updated_at?: Maybe<Order_By>,
  user_submitter_recommendations_aggregate?: Maybe<User_Submitter_Recommendations_Aggregate_Order_By>,
  votes_aggregate?: Maybe<Votes_Aggregate_Order_By>,
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsEditor = 'is_editor',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeenAt = 'last_seen_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  email?: Maybe<Scalars['String']>,
  first_name?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_editor?: Maybe<Scalars['Boolean']>,
  last_name?: Maybe<Scalars['String']>,
  last_seen_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsEditor = 'is_editor',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeenAt = 'last_seen_at',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>,
  _gt?: Maybe<Scalars['uuid']>,
  _gte?: Maybe<Scalars['uuid']>,
  _in?: Maybe<Array<Scalars['uuid']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['uuid']>,
  _lte?: Maybe<Scalars['uuid']>,
  _neq?: Maybe<Scalars['uuid']>,
  _nin?: Maybe<Array<Scalars['uuid']>>,
};

/** columns and relationships of "votes" */
export type Votes = {
   __typename?: 'votes',
  created_at: Scalars['timestamptz'],
  is_active?: Maybe<Scalars['Boolean']>,
  /** An object relationship */
  poll: Polls,
  poll_id: Scalars['String'],
  /** An object relationship */
  submission: Submissions,
  submission_id: Scalars['String'],
  user_id: Scalars['String'],
  /** An object relationship */
  voter: Users,
};

/** aggregated selection of "votes" */
export type Votes_Aggregate = {
   __typename?: 'votes_aggregate',
  aggregate?: Maybe<Votes_Aggregate_Fields>,
  nodes: Array<Votes>,
};

/** aggregate fields of "votes" */
export type Votes_Aggregate_Fields = {
   __typename?: 'votes_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<Votes_Max_Fields>,
  min?: Maybe<Votes_Min_Fields>,
};


/** aggregate fields of "votes" */
export type Votes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Votes_Select_Column>>,
  distinct?: Maybe<Scalars['Boolean']>
};

/** order by aggregate values of table "votes" */
export type Votes_Aggregate_Order_By = {
  count?: Maybe<Order_By>,
  max?: Maybe<Votes_Max_Order_By>,
  min?: Maybe<Votes_Min_Order_By>,
};

/** input type for inserting array relation for remote table "votes" */
export type Votes_Arr_Rel_Insert_Input = {
  data: Array<Votes_Insert_Input>,
  on_conflict?: Maybe<Votes_On_Conflict>,
};

/** Boolean expression to filter rows from the table "votes". All fields are combined with a logical 'AND'. */
export type Votes_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Votes_Bool_Exp>>>,
  _not?: Maybe<Votes_Bool_Exp>,
  _or?: Maybe<Array<Maybe<Votes_Bool_Exp>>>,
  created_at?: Maybe<Timestamptz_Comparison_Exp>,
  is_active?: Maybe<Boolean_Comparison_Exp>,
  poll?: Maybe<Polls_Bool_Exp>,
  poll_id?: Maybe<String_Comparison_Exp>,
  submission?: Maybe<Submissions_Bool_Exp>,
  submission_id?: Maybe<String_Comparison_Exp>,
  user_id?: Maybe<String_Comparison_Exp>,
  voter?: Maybe<Users_Bool_Exp>,
};

/** unique or primary key constraints on table "votes" */
export enum Votes_Constraint {
  /** unique or primary key constraint */
  VotesPkey = 'votes_pkey'
}

/** input type for inserting data into table "votes" */
export type Votes_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  is_active?: Maybe<Scalars['Boolean']>,
  poll?: Maybe<Polls_Obj_Rel_Insert_Input>,
  poll_id?: Maybe<Scalars['String']>,
  submission?: Maybe<Submissions_Obj_Rel_Insert_Input>,
  submission_id?: Maybe<Scalars['String']>,
  user_id?: Maybe<Scalars['String']>,
  voter?: Maybe<Users_Obj_Rel_Insert_Input>,
};

/** aggregate max on columns */
export type Votes_Max_Fields = {
   __typename?: 'votes_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  poll_id?: Maybe<Scalars['String']>,
  submission_id?: Maybe<Scalars['String']>,
  user_id?: Maybe<Scalars['String']>,
};

/** order by max() on columns of table "votes" */
export type Votes_Max_Order_By = {
  created_at?: Maybe<Order_By>,
  poll_id?: Maybe<Order_By>,
  submission_id?: Maybe<Order_By>,
  user_id?: Maybe<Order_By>,
};

/** aggregate min on columns */
export type Votes_Min_Fields = {
   __typename?: 'votes_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  poll_id?: Maybe<Scalars['String']>,
  submission_id?: Maybe<Scalars['String']>,
  user_id?: Maybe<Scalars['String']>,
};

/** order by min() on columns of table "votes" */
export type Votes_Min_Order_By = {
  created_at?: Maybe<Order_By>,
  poll_id?: Maybe<Order_By>,
  submission_id?: Maybe<Order_By>,
  user_id?: Maybe<Order_By>,
};

/** response of any mutation on the table "votes" */
export type Votes_Mutation_Response = {
   __typename?: 'votes_mutation_response',
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'],
  /** data of the affected rows by the mutation */
  returning: Array<Votes>,
};

/** input type for inserting object relation for remote table "votes" */
export type Votes_Obj_Rel_Insert_Input = {
  data: Votes_Insert_Input,
  on_conflict?: Maybe<Votes_On_Conflict>,
};

/** on conflict condition type for table "votes" */
export type Votes_On_Conflict = {
  constraint: Votes_Constraint,
  update_columns: Array<Votes_Update_Column>,
  where?: Maybe<Votes_Bool_Exp>,
};

/** ordering options when selecting data from "votes" */
export type Votes_Order_By = {
  created_at?: Maybe<Order_By>,
  is_active?: Maybe<Order_By>,
  poll?: Maybe<Polls_Order_By>,
  poll_id?: Maybe<Order_By>,
  submission?: Maybe<Submissions_Order_By>,
  submission_id?: Maybe<Order_By>,
  user_id?: Maybe<Order_By>,
  voter?: Maybe<Users_Order_By>,
};

/** select columns of table "votes" */
export enum Votes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PollId = 'poll_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "votes" */
export type Votes_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>,
  is_active?: Maybe<Scalars['Boolean']>,
  poll_id?: Maybe<Scalars['String']>,
  submission_id?: Maybe<Scalars['String']>,
  user_id?: Maybe<Scalars['String']>,
};

/** update columns of table "votes" */
export enum Votes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PollId = 'poll_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  UserId = 'user_id'
}

export type ActivePollQueryVariables = {};


export type ActivePollQuery = (
  { __typename?: 'query_root' }
  & { poll: Array<(
    { __typename?: 'polls' }
    & Pick<Polls, 'id' | 'created_at' | 'closed_at' | 'opened_at'>
  )> }
);

export type PollQueryVariables = {
  poll_id: Scalars['String'],
  user_id: Scalars['String']
};


export type PollQuery = (
  { __typename?: 'query_root' }
  & { poll: Maybe<(
    { __typename?: 'polls' }
    & Pick<Polls, 'id' | 'created_at' | 'closed_at' | 'is_open'>
    & { options: (
      { __typename?: 'poll_submission_options_aggregate' }
      & { aggregate: Maybe<(
        { __typename?: 'poll_submission_options_aggregate_fields' }
        & Pick<Poll_Submission_Options_Aggregate_Fields, 'count'>
      )>, nodes: Array<(
        { __typename?: 'poll_submission_options' }
        & { submission: (
          { __typename?: 'submissions' }
          & Pick<Submissions, 'id' | 'username' | 'video_url' | 'image_url' | 'is_video' | 'url' | 'taken_at'>
          & { submitter: (
            { __typename?: 'submitters' }
            & Pick<Submitters, 'id'>
            & { recommendations: Array<(
              { __typename?: 'user_submitter_recommendations' }
              & Pick<User_Submitter_Recommendations, 'id' | 'office' | 'poll_id' | 'user_id'>
            )> }
          ), votes_aggregate: (
            { __typename?: 'votes_aggregate' }
            & { aggregate: Maybe<(
              { __typename?: 'votes_aggregate_fields' }
              & Pick<Votes_Aggregate_Fields, 'count'>
            )>, nodes: Array<(
              { __typename?: 'votes' }
              & Pick<Votes, 'poll_id' | 'submission_id' | 'user_id'>
              & { voter: (
                { __typename?: 'users' }
                & Pick<Users, 'id' | 'email'>
              ) }
            )> }
          ) }
        ) }
      )> }
    ) }
  )> }
);

export type InsertVoteMutationVariables = {
  poll_id: Scalars['String'],
  submission_id: Scalars['String'],
  user_id: Scalars['String']
};


export type InsertVoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_votes: Maybe<(
    { __typename?: 'votes_mutation_response' }
    & Pick<Votes_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteVoteMutationVariables = {
  poll_id: Scalars['String'],
  submission_id: Scalars['String'],
  user_id: Scalars['String']
};


export type DeleteVoteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_votes: Maybe<(
    { __typename?: 'votes_mutation_response' }
    & Pick<Votes_Mutation_Response, 'affected_rows'>
  )> }
);

export type InsertUserSubmitterRecommendationMutationVariables = {
  submitter_id: Scalars['String'],
  user_id: Scalars['String'],
  poll_id: Scalars['String']
};


export type InsertUserSubmitterRecommendationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_submitter_recommendations: Maybe<(
    { __typename?: 'user_submitter_recommendations_mutation_response' }
    & Pick<User_Submitter_Recommendations_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteUserSubmitterRecommendationMutationVariables = {
  submitter_id: Scalars['String'],
  user_id: Scalars['String'],
  poll_id: Scalars['String']
};


export type DeleteUserSubmitterRecommendationMutation = (
  { __typename?: 'mutation_root' }
  & { delete_user_submitter_recommendations: Maybe<(
    { __typename?: 'user_submitter_recommendations_mutation_response' }
    & Pick<User_Submitter_Recommendations_Mutation_Response, 'affected_rows'>
  )> }
);

export type CreateNewPollMutationVariables = {
  poll_id: Scalars['String'],
  submission_options: Array<Poll_Submission_Options_Insert_Input>
};


export type CreateNewPollMutation = (
  { __typename?: 'mutation_root' }
  & { insert_polls: Maybe<(
    { __typename?: 'polls_mutation_response' }
    & Pick<Polls_Mutation_Response, 'affected_rows'>
  )>, insert_poll_submission_options: Maybe<(
    { __typename?: 'poll_submission_options_mutation_response' }
    & Pick<Poll_Submission_Options_Mutation_Response, 'affected_rows'>
  )> }
);

export type ClosePollMutationVariables = {
  user_id: Scalars['String'],
  poll_id: Scalars['String']
};


export type ClosePollMutation = (
  { __typename?: 'mutation_root' }
  & { update_polls: Maybe<(
    { __typename?: 'polls_mutation_response' }
    & Pick<Polls_Mutation_Response, 'affected_rows'>
  )> }
);

export type PollResultsQueryVariables = {
  is_open_states?: Maybe<Array<Scalars['Boolean']>>
};


export type PollResultsQuery = (
  { __typename?: 'query_root' }
  & { polls: Array<(
    { __typename?: 'polls' }
    & Pick<Polls, 'id' | 'is_open' | 'created_at' | 'closed_at'>
    & { options: (
      { __typename?: 'poll_submission_options_aggregate' }
      & { aggregate: Maybe<(
        { __typename?: 'poll_submission_options_aggregate_fields' }
        & Pick<Poll_Submission_Options_Aggregate_Fields, 'count'>
      )> }
    ), distinct_users_who_voted: (
      { __typename?: 'votes_aggregate' }
      & { aggregate: Maybe<(
        { __typename?: 'votes_aggregate_fields' }
        & Pick<Votes_Aggregate_Fields, 'count'>
      )> }
    ), rankings_aggregate: (
      { __typename?: 'poll_submission_vote_rankings_aggregate' }
      & { aggregate: Maybe<(
        { __typename?: 'poll_submission_vote_rankings_aggregate_fields' }
        & { sum: Maybe<(
          { __typename?: 'poll_submission_vote_rankings_sum_fields' }
          & Pick<Poll_Submission_Vote_Rankings_Sum_Fields, 'vote_count'>
        )>, avg: Maybe<(
          { __typename?: 'poll_submission_vote_rankings_avg_fields' }
          & Pick<Poll_Submission_Vote_Rankings_Avg_Fields, 'vote_count'>
        )> }
      )> }
    ), recommendations: Array<(
      { __typename?: 'user_submitter_recommendations' }
      & Pick<User_Submitter_Recommendations, 'id' | 'created_at' | 'poll_id'>
      & { submitter: (
        { __typename?: 'submitters' }
        & Pick<Submitters, 'id' | 'username'>
      ), user: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'email' | 'first_name' | 'last_name'>
      ) }
    )>, winningRankings: (
      { __typename?: 'poll_submission_vote_rankings_aggregate' }
      & { aggregate: Maybe<(
        { __typename?: 'poll_submission_vote_rankings_aggregate_fields' }
        & Pick<Poll_Submission_Vote_Rankings_Aggregate_Fields, 'count'>
      )>, nodes: Array<(
        { __typename?: 'poll_submission_vote_rankings' }
        & Pick<Poll_Submission_Vote_Rankings, 'submission_id' | 'poll_id' | 'vote_count'>
        & { submission: Maybe<(
          { __typename?: 'submissions' }
          & Pick<Submissions, 'id' | 'username' | 'video_url' | 'image_url' | 'is_video' | 'url' | 'taken_at' | 'is_active'>
        )> }
      )> }
    ), runnerUpRankings: (
      { __typename?: 'poll_submission_vote_rankings_aggregate' }
      & { aggregate: Maybe<(
        { __typename?: 'poll_submission_vote_rankings_aggregate_fields' }
        & Pick<Poll_Submission_Vote_Rankings_Aggregate_Fields, 'count'>
      )>, nodes: Array<(
        { __typename?: 'poll_submission_vote_rankings' }
        & Pick<Poll_Submission_Vote_Rankings, 'submission_id' | 'poll_id' | 'vote_count'>
        & { submission: Maybe<(
          { __typename?: 'submissions' }
          & Pick<Submissions, 'id' | 'username' | 'video_url' | 'image_url' | 'is_video' | 'url' | 'taken_at' | 'is_active'>
        )> }
      )> }
    ) }
  )> }
);

export type DisableSubmissionMutationVariables = {
  submission_id: Scalars['String']
};


export type DisableSubmissionMutation = (
  { __typename?: 'mutation_root' }
  & { update_submissions: Maybe<(
    { __typename?: 'submissions_mutation_response' }
    & Pick<Submissions_Mutation_Response, 'affected_rows'>
  )> }
);

export type SubmissionsQueryVariables = {};


export type SubmissionsQuery = (
  { __typename?: 'query_root' }
  & { submissions: Array<(
    { __typename?: 'submissions' }
    & Pick<Submissions, 'id' | 'username' | 'video_url' | 'image_url' | 'is_video' | 'url' | 'taken_at' | 'is_active' | 'created_at'>
  )> }
);

export type SetSubmissionStatusMutationVariables = {
  submission_id: Scalars['String'],
  active: Scalars['Boolean']
};


export type SetSubmissionStatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_submissions: Maybe<(
    { __typename?: 'submissions_mutation_response' }
    & Pick<Submissions_Mutation_Response, 'affected_rows'>
  )> }
);

export type UnassignedSubmissionsQueryVariables = {};


export type UnassignedSubmissionsQuery = (
  { __typename?: 'query_root' }
  & { unassigned_submissions: Array<(
    { __typename?: 'unassigned_submissions' }
    & Pick<Unassigned_Submissions, 'id' | 'username' | 'video_url' | 'image_url' | 'is_video' | 'url' | 'taken_at' | 'is_active' | 'created_at'>
  )> }
);

export type InsertUserMutationVariables = {
  email: Scalars['String'],
  first_name: Scalars['String'],
  last_name: Scalars['String']
};


export type InsertUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<Users_Mutation_Response, 'affected_rows'>
  )> }
);

export type OnlineUsersCountQueryVariables = {};


export type OnlineUsersCountQuery = (
  { __typename?: 'query_root' }
  & { online_users: Array<(
    { __typename?: 'online_users' }
    & Pick<Online_Users, 'count'>
  )> }
);

export type ReopenPollMutationVariables = {
  poll_id: Scalars['String']
};


export type ReopenPollMutation = (
  { __typename?: 'mutation_root' }
  & { update_polls: Maybe<(
    { __typename?: 'polls_mutation_response' }
    & Pick<Polls_Mutation_Response, 'affected_rows'>
  )> }
);


export const ActivePollDocument = gql`
    query ActivePoll {
  poll: polls(where: {is_open: {_eq: true}}, order_by: {opened_at: desc}, limit: 1) {
    id
    created_at
    closed_at
    opened_at
  }
}
    `;
export type ActivePollComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActivePollQuery, ActivePollQueryVariables>, 'query'>;

    export const ActivePollComponent = (props: ActivePollComponentProps) => (
      <ApolloReactComponents.Query<ActivePollQuery, ActivePollQueryVariables> query={ActivePollDocument} {...props} />
    );
    
export type ActivePollProps<TChildProps = {}> = ApolloReactHoc.DataProps<ActivePollQuery, ActivePollQueryVariables> & TChildProps;
export function withActivePoll<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ActivePollQuery,
  ActivePollQueryVariables,
  ActivePollProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ActivePollQuery, ActivePollQueryVariables, ActivePollProps<TChildProps>>(ActivePollDocument, {
      alias: 'activePoll',
      ...operationOptions
    });
};

/**
 * __useActivePollQuery__
 *
 * To run a query within a React component, call `useActivePollQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivePollQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivePollQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivePollQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActivePollQuery, ActivePollQueryVariables>) {
        return ApolloReactHooks.useQuery<ActivePollQuery, ActivePollQueryVariables>(ActivePollDocument, baseOptions);
      }
export function useActivePollLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivePollQuery, ActivePollQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActivePollQuery, ActivePollQueryVariables>(ActivePollDocument, baseOptions);
        }
export type ActivePollQueryHookResult = ReturnType<typeof useActivePollQuery>;
export type ActivePollLazyQueryHookResult = ReturnType<typeof useActivePollLazyQuery>;
export type ActivePollQueryResult = ApolloReactCommon.QueryResult<ActivePollQuery, ActivePollQueryVariables>;
export const PollDocument = gql`
    query Poll($poll_id: String!, $user_id: String!) {
  poll: polls_by_pk(id: $poll_id) {
    id
    created_at
    closed_at
    is_open
    options: poll_submission_options_aggregate(where: {is_active: {_eq: true}, submission: {is_active: {_eq: true}}}) {
      aggregate {
        count
      }
      nodes {
        submission {
          id
          username
          video_url
          image_url
          is_video
          url
          taken_at
          submitter {
            id
            recommendations: user_submitter_recommendations(where: {user_id: {_eq: $user_id}, poll_id: {_eq: $poll_id}}) {
              id
              office
              poll_id
              user_id
            }
          }
          votes_aggregate(where: {poll_id: {_eq: $poll_id}, voter: {id: {_eq: $user_id}}}) {
            aggregate {
              count
            }
            nodes {
              poll_id
              submission_id
              user_id
              voter {
                id
                email
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type PollComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PollQuery, PollQueryVariables>, 'query'> & ({ variables: PollQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PollComponent = (props: PollComponentProps) => (
      <ApolloReactComponents.Query<PollQuery, PollQueryVariables> query={PollDocument} {...props} />
    );
    
export type PollProps<TChildProps = {}> = ApolloReactHoc.DataProps<PollQuery, PollQueryVariables> & TChildProps;
export function withPoll<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PollQuery,
  PollQueryVariables,
  PollProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PollQuery, PollQueryVariables, PollProps<TChildProps>>(PollDocument, {
      alias: 'poll',
      ...operationOptions
    });
};

/**
 * __usePollQuery__
 *
 * To run a query within a React component, call `usePollQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollQuery({
 *   variables: {
 *      poll_id: // value for 'poll_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function usePollQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PollQuery, PollQueryVariables>) {
        return ApolloReactHooks.useQuery<PollQuery, PollQueryVariables>(PollDocument, baseOptions);
      }
export function usePollLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PollQuery, PollQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PollQuery, PollQueryVariables>(PollDocument, baseOptions);
        }
export type PollQueryHookResult = ReturnType<typeof usePollQuery>;
export type PollLazyQueryHookResult = ReturnType<typeof usePollLazyQuery>;
export type PollQueryResult = ApolloReactCommon.QueryResult<PollQuery, PollQueryVariables>;
export const InsertVoteDocument = gql`
    mutation InsertVote($poll_id: String!, $submission_id: String!, $user_id: String!) {
  insert_votes(objects: {poll_id: $poll_id, user_id: $user_id, submission_id: $submission_id}) {
    affected_rows
  }
}
    `;
export type InsertVoteMutationFn = ApolloReactCommon.MutationFunction<InsertVoteMutation, InsertVoteMutationVariables>;
export type InsertVoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertVoteMutation, InsertVoteMutationVariables>, 'mutation'>;

    export const InsertVoteComponent = (props: InsertVoteComponentProps) => (
      <ApolloReactComponents.Mutation<InsertVoteMutation, InsertVoteMutationVariables> mutation={InsertVoteDocument} {...props} />
    );
    
export type InsertVoteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<InsertVoteMutation, InsertVoteMutationVariables> & TChildProps;
export function withInsertVote<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InsertVoteMutation,
  InsertVoteMutationVariables,
  InsertVoteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, InsertVoteMutation, InsertVoteMutationVariables, InsertVoteProps<TChildProps>>(InsertVoteDocument, {
      alias: 'insertVote',
      ...operationOptions
    });
};

/**
 * __useInsertVoteMutation__
 *
 * To run a mutation, you first call `useInsertVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertVoteMutation, { data, loading, error }] = useInsertVoteMutation({
 *   variables: {
 *      poll_id: // value for 'poll_id'
 *      submission_id: // value for 'submission_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useInsertVoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertVoteMutation, InsertVoteMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertVoteMutation, InsertVoteMutationVariables>(InsertVoteDocument, baseOptions);
      }
export type InsertVoteMutationHookResult = ReturnType<typeof useInsertVoteMutation>;
export type InsertVoteMutationResult = ApolloReactCommon.MutationResult<InsertVoteMutation>;
export type InsertVoteMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertVoteMutation, InsertVoteMutationVariables>;
export const DeleteVoteDocument = gql`
    mutation DeleteVote($poll_id: String!, $submission_id: String!, $user_id: String!) {
  delete_votes(where: {poll_id: {_eq: $poll_id}, user_id: {_eq: $user_id}, submission_id: {_eq: $submission_id}}) {
    affected_rows
  }
}
    `;
export type DeleteVoteMutationFn = ApolloReactCommon.MutationFunction<DeleteVoteMutation, DeleteVoteMutationVariables>;
export type DeleteVoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteVoteMutation, DeleteVoteMutationVariables>, 'mutation'>;

    export const DeleteVoteComponent = (props: DeleteVoteComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteVoteMutation, DeleteVoteMutationVariables> mutation={DeleteVoteDocument} {...props} />
    );
    
export type DeleteVoteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteVoteMutation, DeleteVoteMutationVariables> & TChildProps;
export function withDeleteVote<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteVoteMutation,
  DeleteVoteMutationVariables,
  DeleteVoteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteVoteMutation, DeleteVoteMutationVariables, DeleteVoteProps<TChildProps>>(DeleteVoteDocument, {
      alias: 'deleteVote',
      ...operationOptions
    });
};

/**
 * __useDeleteVoteMutation__
 *
 * To run a mutation, you first call `useDeleteVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVoteMutation, { data, loading, error }] = useDeleteVoteMutation({
 *   variables: {
 *      poll_id: // value for 'poll_id'
 *      submission_id: // value for 'submission_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDeleteVoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVoteMutation, DeleteVoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteVoteMutation, DeleteVoteMutationVariables>(DeleteVoteDocument, baseOptions);
      }
export type DeleteVoteMutationHookResult = ReturnType<typeof useDeleteVoteMutation>;
export type DeleteVoteMutationResult = ApolloReactCommon.MutationResult<DeleteVoteMutation>;
export type DeleteVoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteVoteMutation, DeleteVoteMutationVariables>;
export const InsertUserSubmitterRecommendationDocument = gql`
    mutation InsertUserSubmitterRecommendation($submitter_id: String!, $user_id: String!, $poll_id: String!) {
  insert_user_submitter_recommendations(objects: {submitter_id: $submitter_id, user_id: $user_id, poll_id: $poll_id}) {
    affected_rows
  }
}
    `;
export type InsertUserSubmitterRecommendationMutationFn = ApolloReactCommon.MutationFunction<InsertUserSubmitterRecommendationMutation, InsertUserSubmitterRecommendationMutationVariables>;
export type InsertUserSubmitterRecommendationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertUserSubmitterRecommendationMutation, InsertUserSubmitterRecommendationMutationVariables>, 'mutation'>;

    export const InsertUserSubmitterRecommendationComponent = (props: InsertUserSubmitterRecommendationComponentProps) => (
      <ApolloReactComponents.Mutation<InsertUserSubmitterRecommendationMutation, InsertUserSubmitterRecommendationMutationVariables> mutation={InsertUserSubmitterRecommendationDocument} {...props} />
    );
    
export type InsertUserSubmitterRecommendationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<InsertUserSubmitterRecommendationMutation, InsertUserSubmitterRecommendationMutationVariables> & TChildProps;
export function withInsertUserSubmitterRecommendation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InsertUserSubmitterRecommendationMutation,
  InsertUserSubmitterRecommendationMutationVariables,
  InsertUserSubmitterRecommendationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, InsertUserSubmitterRecommendationMutation, InsertUserSubmitterRecommendationMutationVariables, InsertUserSubmitterRecommendationProps<TChildProps>>(InsertUserSubmitterRecommendationDocument, {
      alias: 'insertUserSubmitterRecommendation',
      ...operationOptions
    });
};

/**
 * __useInsertUserSubmitterRecommendationMutation__
 *
 * To run a mutation, you first call `useInsertUserSubmitterRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserSubmitterRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserSubmitterRecommendationMutation, { data, loading, error }] = useInsertUserSubmitterRecommendationMutation({
 *   variables: {
 *      submitter_id: // value for 'submitter_id'
 *      user_id: // value for 'user_id'
 *      poll_id: // value for 'poll_id'
 *   },
 * });
 */
export function useInsertUserSubmitterRecommendationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUserSubmitterRecommendationMutation, InsertUserSubmitterRecommendationMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUserSubmitterRecommendationMutation, InsertUserSubmitterRecommendationMutationVariables>(InsertUserSubmitterRecommendationDocument, baseOptions);
      }
export type InsertUserSubmitterRecommendationMutationHookResult = ReturnType<typeof useInsertUserSubmitterRecommendationMutation>;
export type InsertUserSubmitterRecommendationMutationResult = ApolloReactCommon.MutationResult<InsertUserSubmitterRecommendationMutation>;
export type InsertUserSubmitterRecommendationMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUserSubmitterRecommendationMutation, InsertUserSubmitterRecommendationMutationVariables>;
export const DeleteUserSubmitterRecommendationDocument = gql`
    mutation DeleteUserSubmitterRecommendation($submitter_id: String!, $user_id: String!, $poll_id: String!) {
  delete_user_submitter_recommendations(where: {submitter_id: {_eq: $submitter_id}, user_id: {_eq: $user_id}, poll_id: {_eq: $poll_id}}) {
    affected_rows
  }
}
    `;
export type DeleteUserSubmitterRecommendationMutationFn = ApolloReactCommon.MutationFunction<DeleteUserSubmitterRecommendationMutation, DeleteUserSubmitterRecommendationMutationVariables>;
export type DeleteUserSubmitterRecommendationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUserSubmitterRecommendationMutation, DeleteUserSubmitterRecommendationMutationVariables>, 'mutation'>;

    export const DeleteUserSubmitterRecommendationComponent = (props: DeleteUserSubmitterRecommendationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUserSubmitterRecommendationMutation, DeleteUserSubmitterRecommendationMutationVariables> mutation={DeleteUserSubmitterRecommendationDocument} {...props} />
    );
    
export type DeleteUserSubmitterRecommendationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteUserSubmitterRecommendationMutation, DeleteUserSubmitterRecommendationMutationVariables> & TChildProps;
export function withDeleteUserSubmitterRecommendation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteUserSubmitterRecommendationMutation,
  DeleteUserSubmitterRecommendationMutationVariables,
  DeleteUserSubmitterRecommendationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteUserSubmitterRecommendationMutation, DeleteUserSubmitterRecommendationMutationVariables, DeleteUserSubmitterRecommendationProps<TChildProps>>(DeleteUserSubmitterRecommendationDocument, {
      alias: 'deleteUserSubmitterRecommendation',
      ...operationOptions
    });
};

/**
 * __useDeleteUserSubmitterRecommendationMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubmitterRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubmitterRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubmitterRecommendationMutation, { data, loading, error }] = useDeleteUserSubmitterRecommendationMutation({
 *   variables: {
 *      submitter_id: // value for 'submitter_id'
 *      user_id: // value for 'user_id'
 *      poll_id: // value for 'poll_id'
 *   },
 * });
 */
export function useDeleteUserSubmitterRecommendationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserSubmitterRecommendationMutation, DeleteUserSubmitterRecommendationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserSubmitterRecommendationMutation, DeleteUserSubmitterRecommendationMutationVariables>(DeleteUserSubmitterRecommendationDocument, baseOptions);
      }
export type DeleteUserSubmitterRecommendationMutationHookResult = ReturnType<typeof useDeleteUserSubmitterRecommendationMutation>;
export type DeleteUserSubmitterRecommendationMutationResult = ApolloReactCommon.MutationResult<DeleteUserSubmitterRecommendationMutation>;
export type DeleteUserSubmitterRecommendationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserSubmitterRecommendationMutation, DeleteUserSubmitterRecommendationMutationVariables>;
export const CreateNewPollDocument = gql`
    mutation CreateNewPoll($poll_id: String!, $submission_options: [poll_submission_options_insert_input!]!) {
  insert_polls(objects: {id: $poll_id, is_open: true, opened_at: "now()"}) {
    affected_rows
  }
  insert_poll_submission_options(objects: $submission_options) {
    affected_rows
  }
}
    `;
export type CreateNewPollMutationFn = ApolloReactCommon.MutationFunction<CreateNewPollMutation, CreateNewPollMutationVariables>;
export type CreateNewPollComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNewPollMutation, CreateNewPollMutationVariables>, 'mutation'>;

    export const CreateNewPollComponent = (props: CreateNewPollComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNewPollMutation, CreateNewPollMutationVariables> mutation={CreateNewPollDocument} {...props} />
    );
    
export type CreateNewPollProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateNewPollMutation, CreateNewPollMutationVariables> & TChildProps;
export function withCreateNewPoll<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateNewPollMutation,
  CreateNewPollMutationVariables,
  CreateNewPollProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateNewPollMutation, CreateNewPollMutationVariables, CreateNewPollProps<TChildProps>>(CreateNewPollDocument, {
      alias: 'createNewPoll',
      ...operationOptions
    });
};

/**
 * __useCreateNewPollMutation__
 *
 * To run a mutation, you first call `useCreateNewPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewPollMutation, { data, loading, error }] = useCreateNewPollMutation({
 *   variables: {
 *      poll_id: // value for 'poll_id'
 *      submission_options: // value for 'submission_options'
 *   },
 * });
 */
export function useCreateNewPollMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewPollMutation, CreateNewPollMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewPollMutation, CreateNewPollMutationVariables>(CreateNewPollDocument, baseOptions);
      }
export type CreateNewPollMutationHookResult = ReturnType<typeof useCreateNewPollMutation>;
export type CreateNewPollMutationResult = ApolloReactCommon.MutationResult<CreateNewPollMutation>;
export type CreateNewPollMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewPollMutation, CreateNewPollMutationVariables>;
export const ClosePollDocument = gql`
    mutation ClosePoll($user_id: String!, $poll_id: String!) {
  update_polls(where: {id: {_eq: $poll_id}}, _set: {is_open: false, closed_by: $user_id, closed_at: "now()"}) {
    affected_rows
  }
}
    `;
export type ClosePollMutationFn = ApolloReactCommon.MutationFunction<ClosePollMutation, ClosePollMutationVariables>;
export type ClosePollComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ClosePollMutation, ClosePollMutationVariables>, 'mutation'>;

    export const ClosePollComponent = (props: ClosePollComponentProps) => (
      <ApolloReactComponents.Mutation<ClosePollMutation, ClosePollMutationVariables> mutation={ClosePollDocument} {...props} />
    );
    
export type ClosePollProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ClosePollMutation, ClosePollMutationVariables> & TChildProps;
export function withClosePoll<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ClosePollMutation,
  ClosePollMutationVariables,
  ClosePollProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ClosePollMutation, ClosePollMutationVariables, ClosePollProps<TChildProps>>(ClosePollDocument, {
      alias: 'closePoll',
      ...operationOptions
    });
};

/**
 * __useClosePollMutation__
 *
 * To run a mutation, you first call `useClosePollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClosePollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closePollMutation, { data, loading, error }] = useClosePollMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      poll_id: // value for 'poll_id'
 *   },
 * });
 */
export function useClosePollMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClosePollMutation, ClosePollMutationVariables>) {
        return ApolloReactHooks.useMutation<ClosePollMutation, ClosePollMutationVariables>(ClosePollDocument, baseOptions);
      }
export type ClosePollMutationHookResult = ReturnType<typeof useClosePollMutation>;
export type ClosePollMutationResult = ApolloReactCommon.MutationResult<ClosePollMutation>;
export type ClosePollMutationOptions = ApolloReactCommon.BaseMutationOptions<ClosePollMutation, ClosePollMutationVariables>;
export const PollResultsDocument = gql`
    query PollResults($is_open_states: [Boolean!]) {
  polls(where: {is_open: {_in: $is_open_states}}, order_by: {closed_at: desc}) {
    id
    is_open
    created_at
    closed_at
    options: poll_submission_options_aggregate(where: {is_active: {_eq: true}, submission: {is_active: {_eq: true}}}) {
      aggregate {
        count
      }
    }
    distinct_users_who_voted: votes_aggregate(distinct_on: user_id) {
      aggregate {
        count
      }
    }
    rankings_aggregate {
      aggregate {
        sum {
          vote_count
        }
        avg {
          vote_count
        }
      }
    }
    recommendations {
      id
      created_at
      poll_id
      submitter {
        id
        username
      }
      user {
        id
        email
        first_name
        last_name
      }
    }
    winningRankings: rankings_aggregate(order_by: [{vote_count: desc}, {submission_id: desc}], limit: 6) {
      aggregate {
        count
      }
      nodes {
        submission_id
        poll_id
        vote_count
        submission {
          id
          username
          video_url
          image_url
          is_video
          url
          taken_at
          is_active
        }
      }
    }
    runnerUpRankings: rankings_aggregate(order_by: [{vote_count: desc}, {submission_id: desc}], offset: 6) {
      aggregate {
        count
      }
      nodes {
        submission_id
        poll_id
        vote_count
        submission {
          id
          username
          video_url
          image_url
          is_video
          url
          taken_at
          is_active
        }
      }
    }
  }
}
    `;
export type PollResultsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PollResultsQuery, PollResultsQueryVariables>, 'query'>;

    export const PollResultsComponent = (props: PollResultsComponentProps) => (
      <ApolloReactComponents.Query<PollResultsQuery, PollResultsQueryVariables> query={PollResultsDocument} {...props} />
    );
    
export type PollResultsProps<TChildProps = {}> = ApolloReactHoc.DataProps<PollResultsQuery, PollResultsQueryVariables> & TChildProps;
export function withPollResults<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PollResultsQuery,
  PollResultsQueryVariables,
  PollResultsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PollResultsQuery, PollResultsQueryVariables, PollResultsProps<TChildProps>>(PollResultsDocument, {
      alias: 'pollResults',
      ...operationOptions
    });
};

/**
 * __usePollResultsQuery__
 *
 * To run a query within a React component, call `usePollResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollResultsQuery({
 *   variables: {
 *      is_open_states: // value for 'is_open_states'
 *   },
 * });
 */
export function usePollResultsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PollResultsQuery, PollResultsQueryVariables>) {
        return ApolloReactHooks.useQuery<PollResultsQuery, PollResultsQueryVariables>(PollResultsDocument, baseOptions);
      }
export function usePollResultsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PollResultsQuery, PollResultsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PollResultsQuery, PollResultsQueryVariables>(PollResultsDocument, baseOptions);
        }
export type PollResultsQueryHookResult = ReturnType<typeof usePollResultsQuery>;
export type PollResultsLazyQueryHookResult = ReturnType<typeof usePollResultsLazyQuery>;
export type PollResultsQueryResult = ApolloReactCommon.QueryResult<PollResultsQuery, PollResultsQueryVariables>;
export const DisableSubmissionDocument = gql`
    mutation DisableSubmission($submission_id: String!) {
  update_submissions(where: {id: {_eq: $submission_id}}, _set: {is_active: false}) {
    affected_rows
  }
}
    `;
export type DisableSubmissionMutationFn = ApolloReactCommon.MutationFunction<DisableSubmissionMutation, DisableSubmissionMutationVariables>;
export type DisableSubmissionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableSubmissionMutation, DisableSubmissionMutationVariables>, 'mutation'>;

    export const DisableSubmissionComponent = (props: DisableSubmissionComponentProps) => (
      <ApolloReactComponents.Mutation<DisableSubmissionMutation, DisableSubmissionMutationVariables> mutation={DisableSubmissionDocument} {...props} />
    );
    
export type DisableSubmissionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DisableSubmissionMutation, DisableSubmissionMutationVariables> & TChildProps;
export function withDisableSubmission<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DisableSubmissionMutation,
  DisableSubmissionMutationVariables,
  DisableSubmissionProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DisableSubmissionMutation, DisableSubmissionMutationVariables, DisableSubmissionProps<TChildProps>>(DisableSubmissionDocument, {
      alias: 'disableSubmission',
      ...operationOptions
    });
};

/**
 * __useDisableSubmissionMutation__
 *
 * To run a mutation, you first call `useDisableSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableSubmissionMutation, { data, loading, error }] = useDisableSubmissionMutation({
 *   variables: {
 *      submission_id: // value for 'submission_id'
 *   },
 * });
 */
export function useDisableSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableSubmissionMutation, DisableSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableSubmissionMutation, DisableSubmissionMutationVariables>(DisableSubmissionDocument, baseOptions);
      }
export type DisableSubmissionMutationHookResult = ReturnType<typeof useDisableSubmissionMutation>;
export type DisableSubmissionMutationResult = ApolloReactCommon.MutationResult<DisableSubmissionMutation>;
export type DisableSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableSubmissionMutation, DisableSubmissionMutationVariables>;
export const SubmissionsDocument = gql`
    query Submissions {
  submissions(order_by: {taken_at: desc, id: desc}, limit: 500) {
    id
    username
    video_url
    image_url
    is_video
    url
    taken_at
    is_active
    created_at
  }
}
    `;
export type SubmissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubmissionsQuery, SubmissionsQueryVariables>, 'query'>;

    export const SubmissionsComponent = (props: SubmissionsComponentProps) => (
      <ApolloReactComponents.Query<SubmissionsQuery, SubmissionsQueryVariables> query={SubmissionsDocument} {...props} />
    );
    
export type SubmissionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<SubmissionsQuery, SubmissionsQueryVariables> & TChildProps;
export function withSubmissions<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmissionsQuery,
  SubmissionsQueryVariables,
  SubmissionsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, SubmissionsQuery, SubmissionsQueryVariables, SubmissionsProps<TChildProps>>(SubmissionsDocument, {
      alias: 'submissions',
      ...operationOptions
    });
};

/**
 * __useSubmissionsQuery__
 *
 * To run a query within a React component, call `useSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubmissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, baseOptions);
      }
export function useSubmissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, baseOptions);
        }
export type SubmissionsQueryHookResult = ReturnType<typeof useSubmissionsQuery>;
export type SubmissionsLazyQueryHookResult = ReturnType<typeof useSubmissionsLazyQuery>;
export type SubmissionsQueryResult = ApolloReactCommon.QueryResult<SubmissionsQuery, SubmissionsQueryVariables>;
export const SetSubmissionStatusDocument = gql`
    mutation SetSubmissionStatus($submission_id: String!, $active: Boolean!) {
  update_submissions(where: {id: {_eq: $submission_id}}, _set: {is_active: $active}) {
    affected_rows
  }
}
    `;
export type SetSubmissionStatusMutationFn = ApolloReactCommon.MutationFunction<SetSubmissionStatusMutation, SetSubmissionStatusMutationVariables>;
export type SetSubmissionStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetSubmissionStatusMutation, SetSubmissionStatusMutationVariables>, 'mutation'>;

    export const SetSubmissionStatusComponent = (props: SetSubmissionStatusComponentProps) => (
      <ApolloReactComponents.Mutation<SetSubmissionStatusMutation, SetSubmissionStatusMutationVariables> mutation={SetSubmissionStatusDocument} {...props} />
    );
    
export type SetSubmissionStatusProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetSubmissionStatusMutation, SetSubmissionStatusMutationVariables> & TChildProps;
export function withSetSubmissionStatus<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetSubmissionStatusMutation,
  SetSubmissionStatusMutationVariables,
  SetSubmissionStatusProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetSubmissionStatusMutation, SetSubmissionStatusMutationVariables, SetSubmissionStatusProps<TChildProps>>(SetSubmissionStatusDocument, {
      alias: 'setSubmissionStatus',
      ...operationOptions
    });
};

/**
 * __useSetSubmissionStatusMutation__
 *
 * To run a mutation, you first call `useSetSubmissionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubmissionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubmissionStatusMutation, { data, loading, error }] = useSetSubmissionStatusMutation({
 *   variables: {
 *      submission_id: // value for 'submission_id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useSetSubmissionStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetSubmissionStatusMutation, SetSubmissionStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<SetSubmissionStatusMutation, SetSubmissionStatusMutationVariables>(SetSubmissionStatusDocument, baseOptions);
      }
export type SetSubmissionStatusMutationHookResult = ReturnType<typeof useSetSubmissionStatusMutation>;
export type SetSubmissionStatusMutationResult = ApolloReactCommon.MutationResult<SetSubmissionStatusMutation>;
export type SetSubmissionStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<SetSubmissionStatusMutation, SetSubmissionStatusMutationVariables>;
export const UnassignedSubmissionsDocument = gql`
    query UnassignedSubmissions {
  unassigned_submissions(order_by: [{username: asc}, {created_at: desc}]) {
    id
    username
    video_url
    image_url
    is_video
    url
    taken_at
    is_active
    created_at
  }
}
    `;
export type UnassignedSubmissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnassignedSubmissionsQuery, UnassignedSubmissionsQueryVariables>, 'query'>;

    export const UnassignedSubmissionsComponent = (props: UnassignedSubmissionsComponentProps) => (
      <ApolloReactComponents.Query<UnassignedSubmissionsQuery, UnassignedSubmissionsQueryVariables> query={UnassignedSubmissionsDocument} {...props} />
    );
    
export type UnassignedSubmissionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<UnassignedSubmissionsQuery, UnassignedSubmissionsQueryVariables> & TChildProps;
export function withUnassignedSubmissions<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnassignedSubmissionsQuery,
  UnassignedSubmissionsQueryVariables,
  UnassignedSubmissionsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UnassignedSubmissionsQuery, UnassignedSubmissionsQueryVariables, UnassignedSubmissionsProps<TChildProps>>(UnassignedSubmissionsDocument, {
      alias: 'unassignedSubmissions',
      ...operationOptions
    });
};

/**
 * __useUnassignedSubmissionsQuery__
 *
 * To run a query within a React component, call `useUnassignedSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnassignedSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassignedSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnassignedSubmissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnassignedSubmissionsQuery, UnassignedSubmissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<UnassignedSubmissionsQuery, UnassignedSubmissionsQueryVariables>(UnassignedSubmissionsDocument, baseOptions);
      }
export function useUnassignedSubmissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnassignedSubmissionsQuery, UnassignedSubmissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnassignedSubmissionsQuery, UnassignedSubmissionsQueryVariables>(UnassignedSubmissionsDocument, baseOptions);
        }
export type UnassignedSubmissionsQueryHookResult = ReturnType<typeof useUnassignedSubmissionsQuery>;
export type UnassignedSubmissionsLazyQueryHookResult = ReturnType<typeof useUnassignedSubmissionsLazyQuery>;
export type UnassignedSubmissionsQueryResult = ApolloReactCommon.QueryResult<UnassignedSubmissionsQuery, UnassignedSubmissionsQueryVariables>;
export const InsertUserDocument = gql`
    mutation InsertUser($email: String!, $first_name: String!, $last_name: String!) {
  insert_users(objects: {email: $email, first_name: $first_name, last_name: $last_name, last_seen_at: "now()"}, on_conflict: {constraint: users_pkey, update_columns: [first_name, last_name, last_seen_at]}) {
    affected_rows
  }
}
    `;
export type InsertUserMutationFn = ApolloReactCommon.MutationFunction<InsertUserMutation, InsertUserMutationVariables>;
export type InsertUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertUserMutation, InsertUserMutationVariables>, 'mutation'>;

    export const InsertUserComponent = (props: InsertUserComponentProps) => (
      <ApolloReactComponents.Mutation<InsertUserMutation, InsertUserMutationVariables> mutation={InsertUserDocument} {...props} />
    );
    
export type InsertUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<InsertUserMutation, InsertUserMutationVariables> & TChildProps;
export function withInsertUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InsertUserMutation,
  InsertUserMutationVariables,
  InsertUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, InsertUserMutation, InsertUserMutationVariables, InsertUserProps<TChildProps>>(InsertUserDocument, {
      alias: 'insertUser',
      ...operationOptions
    });
};

/**
 * __useInsertUserMutation__
 *
 * To run a mutation, you first call `useInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserMutation, { data, loading, error }] = useInsertUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *   },
 * });
 */
export function useInsertUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUserMutation, InsertUserMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUserMutation, InsertUserMutationVariables>(InsertUserDocument, baseOptions);
      }
export type InsertUserMutationHookResult = ReturnType<typeof useInsertUserMutation>;
export type InsertUserMutationResult = ApolloReactCommon.MutationResult<InsertUserMutation>;
export type InsertUserMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUserMutation, InsertUserMutationVariables>;
export const OnlineUsersCountDocument = gql`
    query OnlineUsersCount {
  online_users {
    count
  }
}
    `;
export type OnlineUsersCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OnlineUsersCountQuery, OnlineUsersCountQueryVariables>, 'query'>;

    export const OnlineUsersCountComponent = (props: OnlineUsersCountComponentProps) => (
      <ApolloReactComponents.Query<OnlineUsersCountQuery, OnlineUsersCountQueryVariables> query={OnlineUsersCountDocument} {...props} />
    );
    
export type OnlineUsersCountProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnlineUsersCountQuery, OnlineUsersCountQueryVariables> & TChildProps;
export function withOnlineUsersCount<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnlineUsersCountQuery,
  OnlineUsersCountQueryVariables,
  OnlineUsersCountProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, OnlineUsersCountQuery, OnlineUsersCountQueryVariables, OnlineUsersCountProps<TChildProps>>(OnlineUsersCountDocument, {
      alias: 'onlineUsersCount',
      ...operationOptions
    });
};

/**
 * __useOnlineUsersCountQuery__
 *
 * To run a query within a React component, call `useOnlineUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineUsersCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnlineUsersCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OnlineUsersCountQuery, OnlineUsersCountQueryVariables>) {
        return ApolloReactHooks.useQuery<OnlineUsersCountQuery, OnlineUsersCountQueryVariables>(OnlineUsersCountDocument, baseOptions);
      }
export function useOnlineUsersCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OnlineUsersCountQuery, OnlineUsersCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OnlineUsersCountQuery, OnlineUsersCountQueryVariables>(OnlineUsersCountDocument, baseOptions);
        }
export type OnlineUsersCountQueryHookResult = ReturnType<typeof useOnlineUsersCountQuery>;
export type OnlineUsersCountLazyQueryHookResult = ReturnType<typeof useOnlineUsersCountLazyQuery>;
export type OnlineUsersCountQueryResult = ApolloReactCommon.QueryResult<OnlineUsersCountQuery, OnlineUsersCountQueryVariables>;
export const ReopenPollDocument = gql`
    mutation ReopenPoll($poll_id: String!) {
  update_polls(where: {id: {_eq: $poll_id}}, _set: {is_open: true}) {
    affected_rows
  }
}
    `;
export type ReopenPollMutationFn = ApolloReactCommon.MutationFunction<ReopenPollMutation, ReopenPollMutationVariables>;
export type ReopenPollComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReopenPollMutation, ReopenPollMutationVariables>, 'mutation'>;

    export const ReopenPollComponent = (props: ReopenPollComponentProps) => (
      <ApolloReactComponents.Mutation<ReopenPollMutation, ReopenPollMutationVariables> mutation={ReopenPollDocument} {...props} />
    );
    
export type ReopenPollProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReopenPollMutation, ReopenPollMutationVariables> & TChildProps;
export function withReopenPoll<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReopenPollMutation,
  ReopenPollMutationVariables,
  ReopenPollProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReopenPollMutation, ReopenPollMutationVariables, ReopenPollProps<TChildProps>>(ReopenPollDocument, {
      alias: 'reopenPoll',
      ...operationOptions
    });
};

/**
 * __useReopenPollMutation__
 *
 * To run a mutation, you first call `useReopenPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenPollMutation, { data, loading, error }] = useReopenPollMutation({
 *   variables: {
 *      poll_id: // value for 'poll_id'
 *   },
 * });
 */
export function useReopenPollMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReopenPollMutation, ReopenPollMutationVariables>) {
        return ApolloReactHooks.useMutation<ReopenPollMutation, ReopenPollMutationVariables>(ReopenPollDocument, baseOptions);
      }
export type ReopenPollMutationHookResult = ReturnType<typeof useReopenPollMutation>;
export type ReopenPollMutationResult = ApolloReactCommon.MutationResult<ReopenPollMutation>;
export type ReopenPollMutationOptions = ApolloReactCommon.BaseMutationOptions<ReopenPollMutation, ReopenPollMutationVariables>;