import React, { createContext, useContext } from "react"

export const AppContext = createContext<{
  user?: {
    id: string
    email: string
    role: "user" | "editor"
  }
}>({
  user: undefined,
})

export const useAppContext = () => useContext(AppContext)
